import React, { Component } from 'react';

import backBtn from '../assets/images/icons/LeftArrow.svg';

import CharPanel from '../components/CharPanel';
import { withRouter } from 'react-router-dom'

class ChooseCharacter extends Component {


    constructor(props) {
        super(props);

        this.state = {
            characters: [
                {
                    "id": 1,
                    "imgDir": require('../assets/images/characters/Character-01.png'),
                    "selected": false,
                    "color": '#FFB1E4'
                },
                {
                    "id": 2,
                    "imgDir": require('../assets/images/characters/Character-02.png'),
                    "selected": false,
                    "color": '#FB795C'
                },
                {
                    "id": 3,
                    "imgDir": require('../assets/images/characters/Character-04.png'),
                    "selected": false,
                    "color": 'gold'
                },
                {
                    "id": 4,
                    "imgDir": require('../assets/images/characters/Character-03.png'),
                    "selected": false,
                    "color": '#68BCC1'
                }
            ],
            selected: this.props.characterID ? this.props.characterID : null,
            errMsg: ''
        }
        this.setSelected = this.setSelected.bind(this);
        this.goNext = this.goNext.bind(this);
    }

    componentWillMount() {
        if (localStorage.getItem('character') !== null) {
            let char = Number(localStorage.getItem('character'));
            this.setSelected(char);
        } else {
            if (this.props.hasOwnProperty('characterID')) {
                this.setState({ selected: this.props.characterID });
            }
        }
    }


    setSelected = (el) => {
        this.setState({
            selected: el
        })
        this.props.setCharacter(el);
        localStorage.setItem('character', el);
    }

    goNext = () => {
        if (this.state.selected !== null && this.state.selected > 0) {
            this.props.setCharacter(this.state.selected);
            this.props.history.push({
                pathname: '/customize-character',
                state: {
                    home: {
                        name: this.state.name,
                        age: this.state.age,
                        email: this.state.email
                    }
                }
            })
        } else {
            this.setState({
                errMsg: 'Please select a character...'
            })
        }
    }

    render() {
        return (
            <div id="choose-character">
                <div className="top f-width">
                    <a className="backBtn light-blue" onClick={() => this.props.history.goBack()}>
                        <img className="centerX" src={backBtn} alt="" />
                    Back
                    </a>
                    <h3 className="f-width centerAlign dark-blue">You Choose</h3>
                </div>
                <div className="char-panels">
                    {this.state.characters.map((l, index) => (
                        <CharPanel
                            imgDir={l.imgDir}
                            color={l.color}
                            selected={l.id === this.state.selected}
                            onSelect={() => {
                                this.setSelected(l.id)
                            }} />
                    ))}

                    {this.state.errMsg !== '' && <p style={{ fontSize: 15, marginLeft: 20, marginBottom: 0, fontWeight: '600', color: '#B164A5' }}>{this.state.errMsg}</p>}


                    <div className="f-width centerX rel">
                        <a onClick={this.goNext} className="btn font-weight-bold bg-soft-red white-text centerAlign">
                            Confirm
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(ChooseCharacter);
