import React, { Component } from 'react';

export default class CharFour extends Component {

    render() {
        let trousersEl = <g id="Trousers">
            <rect className="cls-3" x="68.4" y="417.8" width="75" height="60" />
            <rect className="cls-3" x="154.4" y="417.8" width="75" height="60" />
            <rect className="cls-2" x="68.4" y="417.8" width="75" height="129" />
            <rect className="cls-2" x="154.4" y="417.8" width="75" height="129" />
        </g>;
        let shortsEl = <g id="Shorts">
            <rect className="cls-2" x="68.4" y="417.8" width="75" height="60" />
            <rect className="cls-2" x="154.4" y="417.8" width="75" height="60" />
        </g>;

        return (
            <svg xmlns="http://www.w3.org/2000/svg" id="char_four" xlink="http://www.w3.org/1999/xlink" viewBox="0 0 307.4 566.8">
                <defs>
                    <clipPath id="clip-path" transform="translate(-2204.6 -136.2)">
                        <ellipse className="cls-1" cx="2327" cy="308" rx="16" ry="23" />
                    </clipPath>
                    <clipPath id="clip-path-2" transform="translate(-2204.6 -136.2)">
                        <ellipse className="cls-1" cx="2363" cy="308" rx="16" ry="23" />
                    </clipPath>
                </defs>

                {this.props.showShorts ? shortsEl : trousersEl}

                <g id="Layer_12" data-name="Layer 12">
                    <path className="cls-4" d="M2475.5,695a20.3,20.3,0,0,1-.8,5.5h-99.8a33.1,33.1,0,0,1-.4-5.5,21.8,21.8,0,0,1,1.6-7.9,3.6,3.6,0,0,1,.3-.7h98.2A40.5,40.5,0,0,1,2475.5,695Z" transform="translate(-2204.6 -136.2)" />
                    <path className="cls-5" d="M2474.6,686.4h-98.2c7.8-18.2,39.2-31.9,58.6-31.9A40.6,40.6,0,0,1,2474.6,686.4Z" transform="translate(-2204.6 -136.2)" />
                    <path className="cls-4" d="M2329.5,695a33.1,33.1,0,0,1-.4,5.5h-99.8a20.3,20.3,0,0,1-.8-5.5,39.5,39.5,0,0,1,.9-8.5h98.3A20.9,20.9,0,0,1,2329.5,695Z" transform="translate(-2204.6 -136.2)" />
                    <path className="cls-5" d="M2327.7,686.5h-98.3a40.5,40.5,0,0,1,39.6-32C2288.4,654.5,2319.8,668.2,2327.7,686.5Z" transform="translate(-2204.6 -136.2)" />
                    <path className="cls-6" d="M2229.4,686.5a39.5,39.5,0,0,0-.9,8.5,20.3,20.3,0,0,0,.8,5.5h99.8a33.1,33.1,0,0,0,.4-5.5,20.9,20.9,0,0,0-1.8-8.5c-7.9-18.3-39.3-32-58.7-32A40.5,40.5,0,0,0,2229.4,686.5Z" transform="translate(-2204.6 -136.2)" />
                    <path className="cls-6" d="M2474.6,686.4a40.5,40.5,0,0,1,.9,8.6,20.3,20.3,0,0,1-.8,5.5h-99.8a33.1,33.1,0,0,1-.4-5.5,21.8,21.8,0,0,1,1.6-7.9,3.6,3.6,0,0,1,.3-.7c7.8-18.2,39.2-31.9,58.6-31.9A40.6,40.6,0,0,1,2474.6,686.4Z" transform="translate(-2204.6 -136.2)" />
                    <line className="cls-6" x1="269.4" y1="550.3" x2="171.9" y2="550.3" />
                    <polyline className="cls-6" points="123.4 550.3 123.1 550.3 24.9 550.3" />
                </g>
                <g id="T-shirt">
                    <path className="cls-7" fill={this.props.tshirtColor} d="M2451,443.8V462h-24.8l5.9,24.1,8.7,34.9,7.5,34.3a15.7,15.7,0,0,1-15.6,19.1H2277.8a15.7,15.7,0,0,1-15.8-18.2l2.8-19.2,9.6-51.2v-.2L2279,462h-24V443.8a30.8,30.8,0,0,1,30.8-30.8h134.4A30.8,30.8,0,0,1,2451,443.8Z" transform="translate(-2204.6 -136.2)" />
                </g>
                <g id="T-shirt_Outline" data-name="T-shirt Outline">
                    <path className="cls-8" d="M2479.2,537.6a30.3,30.3,0,0,1,21.7,30.4,30.6,30.6,0,0,1-29.1,29.2c-10.3.4-17.6-5.6-23.4-13.1-.6.8-3.2,1.4-3.8,1.4a13.6,13.6,0,0,1-13.4-11.1h1.5a15.7,15.7,0,0,0,15.6-19.1l-7.5-34.3-8.7-34.9-5.9-24.1h18.7Z" transform="translate(-2204.6 -136.2)" />
                    <path className="cls-8" d="M2279,462l-4.6,23.6v.2l-9.6,51.2-2.8,19.2a15.8,15.8,0,0,0,14.8,18.2,13.6,13.6,0,0,1-13.4,11.1c-.6,0-3.2-.6-3.8-1.4-5.8,7.5-13.1,13.5-23.4,13.1a30.6,30.6,0,0,1-29.1-29.2,30.3,30.3,0,0,1,21.7-30.4l34.3-75.6Z" transform="translate(-2204.6 -136.2)" />
                    <path className="cls-6" d="M2263.1,462l-34.3,75.6a30.3,30.3,0,0,0-21.7,30.4,30.6,30.6,0,0,0,29.1,29.2c10.3.4,17.6-5.6,23.4-13.1.6.8,3.2,1.4,3.8,1.4a13.6,13.6,0,0,0,13.4-11.1,19.5,19.5,0,0,0,.2-2.5" transform="translate(-2204.6 -136.2)" />
                    <path className="cls-6" d="M2444.9,462l34.3,75.6a30.3,30.3,0,0,1,21.7,30.4,30.6,30.6,0,0,1-29.1,29.2c-10.3.4-17.6-5.6-23.4-13.1-.6.8-3.2,1.4-3.8,1.4a13.6,13.6,0,0,1-13.4-11.1,19.5,19.5,0,0,1-.2-2.5" transform="translate(-2204.6 -136.2)" />
                    <path className="cls-6" d="M2444.9,462h6.1V443.8a30.8,30.8,0,0,0-30.8-30.8H2285.8a30.8,30.8,0,0,0-30.8,30.8V462h24l-4.6,23.6v.2l-9.6,51.2-2.8,19.2a15.8,15.8,0,0,0,14.8,18.2h155.9a15.7,15.7,0,0,0,15.6-19.1l-7.5-34.3-8.7-34.9-5.9-24.1Z" transform="translate(-2204.6 -136.2)" />
                    <path className="cls-6" d="M2352,446" transform="translate(-2204.6 -136.2)" />
                    <path className="cls-6" d="M2319,425.8s4,30.2,34,31.2,33.9-35.6,33.9-35.6" transform="translate(-2204.6 -136.2)" />
                </g>
                <g id="neck">
                    <path className="cls-9" d="M125.9,276.3h44a0,0,0,0,1,0,0v12a22,22,0,0,1-22,22h0a22,22,0,0,1-22-22v-12a0,0,0,0,1,0,0Z" />
                </g>
                <g id="HairColour-2" data-name="HairColour">
                    <path className="cls-10" fill={this.props.hairColor} d="M2481.2,262a4.7,4.7,0,0,1-4,7.6c-5.7-.6-12.9-1.6-20.7-3.1V264a104.5,104.5,0,0,0-208.9-4.6c-22.6-1.9-32.2-12.7-23.6-15,17-4.6,14-25,51-78.4,23.2-33.4,89-35,107.2-10.9,15.9-2.5,35.1,2.8,52.3,30.4C2463,231.2,2472.4,251.1,2481.2,262Z" transform="translate(-2204.6 -136.2)" />
                    <path className="cls-10" fill={this.props.hairColor} d="M2428.3,258.9a182.9,182.9,0,0,0,28.2,7.6,104.2,104.2,0,0,1-18.5,56.9v-.9A228.3,228.3,0,0,0,2428.3,258.9Z" transform="translate(-2204.6 -136.2)" />
                    <path className="cls-10" fill={this.props.hairColor} d="M2352,159.5A104.5,104.5,0,0,1,2456.5,264v2.5a182.9,182.9,0,0,1-28.2-7.6C2415,214,2388.1,173,2348,173s-68.4,41.1-82.6,86.1a89.6,89.6,0,0,1-17.8.3A104.5,104.5,0,0,1,2352,159.5Z" transform="translate(-2204.6 -136.2)" />
                    <path className="cls-10" fill={this.props.hairColor} d="M2348,173c40.1,0,67,41,80.3,85.9a131.5,131.5,0,0,1-18.8-8.4c-16.7-9.3-31.7-28.3-42.6-45.2a184.7,184.7,0,0,1-21.9,18.6c-4,3-8,5.8-11.9,8.3-26.5,17.4-49.5,24.8-67.7,26.9C2279.6,214.1,2307.9,173,2348,173Z" transform="translate(-2204.6 -136.2)" />
                    <path className="cls-10" fill={this.props.hairColor} d="M2265.4,259.1a221.6,221.6,0,0,0-9.5,46,104.1,104.1,0,0,1-8.4-41.1c0-1.5,0-3.1.1-4.6A89.6,89.6,0,0,0,2265.4,259.1Z" transform="translate(-2204.6 -136.2)" />
                    <path className="cls-6" fill={this.props.hairColor} d="M2265.4,259.1" transform="translate(-2204.6 -136.2)" />
                    <path className="cls-6" fill={this.props.hairColor} d="M2428.3,258.9" transform="translate(-2204.6 -136.2)" />
                </g>
                <g id="Layer_19" data-name="Layer 19">
                    <path className="cls-8" d="M2366.9,205.3c10.9,16.9,25.9,35.9,42.6,45.2a131.5,131.5,0,0,0,18.8,8.4,228.3,228.3,0,0,1,9.7,63.6v.9a104.5,104.5,0,0,1-182.1-18.3,221.6,221.6,0,0,1,9.5-46c18.2-2.1,41.2-9.5,67.7-26.9,3.9-2.5,7.9-5.3,11.9-8.3A184.7,184.7,0,0,0,2366.9,205.3Z" transform="translate(-2204.6 -136.2)" />
                    <path className="cls-8" d="M2255.9,305.1A104.5,104.5,0,0,0,2438,323.4c-.3,59-31.4,106.6-90.5,106.6S2255,381.9,2255,322.5Q2255,314.1,2255.9,305.1Z" transform="translate(-2204.6 -136.2)" />
                </g>
                <g id="Layer_18" data-name="Layer 18">
                    <path className="cls-6" d="M2428.3,258.9a228.3,228.3,0,0,1,9.7,63.6v.9c-.3,59-31.4,106.6-90.5,106.6S2255,381.9,2255,322.5q0-8.4.9-17.4a221.6,221.6,0,0,1,9.5-46" transform="translate(-2204.6 -136.2)" />
                </g>
                <g id="Head">
                    <circle className="cls-11" cx="96.4" cy="208.8" r="19" />
                    <circle className="cls-11" cx="191.4" cy="205.8" r="19" />
                    <path className="cls-12" d="M2375,360c0,9.4-12.5,17-28,17s-28-7.6-28-17" transform="translate(-2204.6 -136.2)" />
                    <g className="cls-13">
                        <ellipse className="cls-5" cx="122.4" cy="171.8" rx="16" ry="23" />
                        <ellipse className="cls-5" cx="122.4" cy="171.8" rx="16" ry="23" />
                        <circle cx="128.4" cy="172.8" r="9" />
                    </g>
                    <g className="cls-14">
                        <ellipse className="cls-5" cx="158.4" cy="171.8" rx="16" ry="23" />
                        <ellipse className="cls-5" cx="158.4" cy="171.8" rx="16" ry="23" />
                        <circle cx="152.4" cy="172.8" r="9" />
                    </g>
                    <path className="cls-15" d="M2389.4,279.6s-5.9-9.2-21-9.4" transform="translate(-2204.6 -136.2)" />
                    <path className="cls-15" d="M2321.4,274.9s-10.8-1.5-20.8,10" transform="translate(-2204.6 -136.2)" />
                    <path className="cls-16" d="M2330.5,250.5" transform="translate(-2204.6 -136.2)" />
                    <path className="cls-16" d="M2324.9,196.8" transform="translate(-2204.6 -136.2)" />
                </g>
                <g id="HairFront">
                    <path className="cls-6" d="M2382.2,155.1c15.9-2.5,35.1,2.8,52.3,30.4,28.5,45.7,37.9,65.6,46.7,76.5a4.7,4.7,0,0,1-4,7.6c-16.5-1.7-45-6.4-67.7-19.1-16.7-9.3-31.7-28.3-42.6-45.2" transform="translate(-2204.6 -136.2)" />
                    <path className="cls-6" d="M2382.2,155.1c-18.2-24.1-84-22.5-107.2,10.9-37,53.4-34,73.8-51,78.4s31.6,38.6,109.1-12.2c3.9-2.5,7.9-5.3,11.9-8.3a184.7,184.7,0,0,0,21.9-18.6C2388.8,183.4,2390.8,166.4,2382.2,155.1Z" transform="translate(-2204.6 -136.2)" />
                    <path className="cls-6" d="M2311.5,197.2c-7.3,18-20,49.7-38.5,60.6" transform="translate(-2204.6 -136.2)" />
                    <path className="cls-6" d="M2352.6,184c-.3,1.2-7.3,34.3-19.6,48" transform="translate(-2204.6 -136.2)" />
                    <line className="cls-6" x1="148" y1="47.8" x2="148" y2="47.7" />
                </g>
                <g id="Layer_16" data-name="Layer 16">
                    <path className="cls-6" d="M2451.5,331.5s-5.5,70.5,11,105,49.4,42.2,29.2,101.6" transform="translate(-2204.6 -136.2)" />
                    <path className="cls-6" d="M2241.5,290.5l1.6-36.5a104.1,104.1,0,0,1,104-99.5h0a104.2,104.2,0,0,1,104.1,104.4L2451,308" transform="translate(-2204.6 -136.2)" />
                    <ellipse className="cls-16" cx="36.7" cy="171.8" rx="20.3" ry="32" />
                    <ellipse className="cls-16" cx="246.7" cy="180.8" rx="20.3" ry="32" />
                    <rect className="cls-16" x="2456.5" y="516.5" width="40" height="82" transform="translate(-1886.9 -914.2) rotate(19.1)" />
                </g>
            </svg>);
    }
}