export default {
    character_gen: 'https://api.nigala.org.uk/api/character-generator',
    apiKey: 'zId7FZuBS50S8vh4Lb6FSQpSTQUcLhsa=',
    getDefaultHeaders() {
        return {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Xaj-Api-Key": this.apiKey,
        }
    }
}
