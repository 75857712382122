import React, { Component } from 'react';
import CharOne from '../assets/images/characters/Character-01.png';
import Logo from '../assets/images/Logo.png';
import { withRouter } from 'react-router-dom';

class Start extends Component {

  constructor(props) {
    super(props);
    this.state = this.initialState();

    this.nameChangeHandler = this.nameChangeHandler.bind(this);
    this.ageChangeHandler = this.ageChangeHandler.bind(this);
    this.emailChangeHandler = this.emailChangeHandler.bind(this);
    this.validate = this.validate.bind(this);
    this.validate = this.validate.bind(this);
    this.validateName = this.validateName.bind(this);
    this.validateAge = this.validateAge.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.goNext = this.goNext.bind(this);
    this.handleNav = this.handleNav.bind(this);
    this.initialState = this.initialState.bind(this);
    this.resetState = this.resetState.bind(this);
  }

  componentWillMount() {
    let name = localStorage.getItem('char_name');
    let age = localStorage.getItem('char_age');
    let email = localStorage.getItem('char_email');

    if(name === null){
      name = this.props.name;
    }
    
    if(age === null){
      age = this.props.age;
    }
    
    if(email === null){
      email = this.props.email;
    }    

    this.setState({
      name: name,
      age: age,
      email: email
    })
    
  }

  initialState = () => ({
    name: this.props.name,
    age: this.props.age,
    email: this.props.email,
    nameIsValid: true,
    ageIsValid: true,
    emailIsValid: true,
    nameErr: '',
    ageErr: '',
    emailErr: '',
  });

  resetState = () => {
    this.setState(this.initialState());
  }

  nameChangeHandler = key => {
    if (key.target.value.trim().length === 0) {
      this.setState({
        nameIsValid: true,
        nameErr: '',
        name: ''
      })
      return;
    }
    else {
      this.setState({
        nameIsValid: true,
        nameErr: ""
      })
    }
    this.setState({
      name: key.target.value
    })
    localStorage.setItem("char_name", key.target.value);
  }

  ageChangeHandler = key => {
    // console.log("Age: " + JSON.stringify(this.state));
    if (key.target.value.trim().length === 0) {
      // console.log('ERR: Age empty.');
      this.setState({
        ageIsValid: true,
        ageErr: '',
        age: ''
      })
      return;
    }
    else {
      if (key.target.value > 0) {
        // console.log("More than 0...");
        this.setState({
          ageIsValid: true,
          ageErr: ''
        })
      }
      else {
        this.setState({
          ageErr: 'Age must not include letters, please re-enter age...',
          ageIsValid: false
        })
      }
      this.setState({
        age: key.target.value
      })
      localStorage.setItem("char_age", key.target.value)
    }
  }

  emailChangeHandler = key => {
    // console.log("Email: " + JSON.stringify(this.state));
    // console.log("KEY: " + key);
    if (key.target.value.trim().length === 0) {
      // console.log('ERR: Email empty.');
      // Setting email err to empty to hide error input styling
      this.setState({
        emailIsValid: true,
        emailErr: ''
      })
    }
    this.setState({ email: key.target.value })
    localStorage.setItem("char_email", key.target.value);
  }

  validate = () => {
    this.validateName();
    this.validateAge();
    this.validateEmail();

    // console.log("props: " + JSON.stringify(this.props))
  }


  validateName = () => {
    if (this.state.name === '') {
      // console.log("apparently empty: " + this.state.name);
      this.setState({
        nameErr: 'Case ID field empty, please enter your case ID...',
        nameIsValid: false
      })
    } else {
      this.setState({
        nameErr: '',
        nameIsValid: true
      })
    }
  }

  validateAge = () => {
    if (this.state.age === '') {
      this.setState({
        ageErr: "Age field empty, please enter your age...",
        ageIsValid: false
      })
    }
    else if (isNaN(Number(this.state.age))) {
      this.setState({
        ageErr: "Age cannot contain letters...",
        ageIsValid: false
      })
    } else {
      this.setState({
        ageErr: '',
        ageIsValid: true
      })
    }
  }

  validateEmail = () => {
    const emailCriteria = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (this.state.email === '') {
      this.setState({
        emailErr: 'Email field empty, please enter your email...',
        emailIsValid: false
      })
    } else {
      if (emailCriteria.test(this.state.email)) {
        this.setState({
          emailErr: '',
          emailIsValid: true
        })
      } else {
        this.setState({
          emailErr: 'Invalid email format, please re-enter email...',
          emailIsValid: false
        })
      }
    }
  }

  goNext = () => {
    this.props.setName(this.state.name);
    this.props.setEmail(this.state.email);
    this.props.setAge(this.state.age);

    // temp workaround
    if (this.state.email.indexOf('@') >= 0 && this.state.email.indexOf('.') >= 0) {
      this.props.history.push({
        pathname: '/choose-character',
        state: {
          home: {
            name: this.state.name,
            age: this.state.age,
            email: this.state.email
          }
        }
      })
    }
  }

  handleNav = () => {
    this.validate();

    if (this.state.name.length && this.state.age.length && this.state.email.length) {
      if (this.state.nameIsValid && this.state.ageIsValid && this.state.emailIsValid) {
        if (this.state.nameErr !== '' && this.state.ageErr !== '' && this.state.emailErr !== '')
          this.setState({
            nameIsValid: true,
            ageIsValid: true,
            emailIsValid: true
          })
        this.goNext();
      } else {
        // err
      }
    }
  }

  render() {

    return (
      <div className="sky-bg" id="start">
        <div className="cont-flex">
          <div className="left m-auto h-width">
            <img className="centerX char" src={CharOne} alt="" />
            <div className="h-width centerX">
              <img src={Logo} className="f-width" style={{ borderRadius: '10px' }} alt="" />
            </div>
          </div>
          <div className="right m-auto h-width">
            <div className="align-items-end">
              <label className="centerX h-width" htmlFor="">Case ID:
                        <input onChange={this.handleInput}
                  placeholder="Enter Case ID..."
                  value={this.state.name}
                  onChange={this.nameChangeHandler}
                  className={this.state.nameIsValid ? "no-outline f-width" : "no-outline f-width inputErr"}
                  type="text" />
                {this.state.nameErr !== '' && <p style={{ fontSize: 10, marginLeft: 20, marginBottom: 0, fontWeight: '600', color: '#B164A5' }}>{this.state.nameErr}</p>}


              </label>
              <label className="centerX h-width" htmlFor="">Age:
                        <input onChange={this.handleInput}
                  placeholder="Enter Age..."
                  value={this.state.age}
                  onChange={this.ageChangeHandler}
                  className={this.state.ageIsValid ? "no-outline f-width" : "no-outline f-width inputErr"}
                  type="text" />
                {this.state.ageErr !== '' && <p style={{ fontSize: 10, marginBottom: 0, marginLeft: 10, fontWeight: '600', color: '#B164A5' }}>{this.state.ageErr}</p>}

              </label>
              <label className="centerX h-width" htmlFor="">Guardian Email:
                        <input onChange={this.handleInput}
                  placeholder="Enter Email..."
                  value={this.state.email}
                  onChange={this.emailChangeHandler}
                  className={this.state.emailIsValid ? "no-outline f-width" : "no-outline f-width inputErr"}
                  type="text" />
                {this.state.emailErr !== '' && <p style={{ fontSize: 10, marginLeft: 20, marginBottom: 0, fontWeight: '600', color: '#B164A5' }}>{this.state.emailErr}</p>}

              </label>
              <div className="centerX h-width btn-cont">
                <button className="h-width btn font-weight-bold" onClick={this.handleNav}>Start</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Start);
