import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import backBtn from '../assets/images/icons/LeftArrow.svg';
import DefaultChar from '../assets/images/characters/Character-01.png';
import YoungWoman from '../assets/images/characters/Family/YoungWoman.png';
import OldWoman from '../assets/images/characters/Family/OlderWoman.png';
import YoungMan from '../assets/images/characters/Family/YoungMan.png';
import TeenBoy from '../assets/images/characters/Family/TeenBoy.png';
import YoungGirl from '../assets/images/characters/Family/YoungGirl.png';
import OldMan from '../assets/images/characters/Family/OlderMan.png';

class FamilyName extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            memberID: '',
            errMsg: ''
        }
        this.goNext = this.goNext.bind(this);
        this.setName = this.setName.bind(this);
        this.capitalizeFirst = this.capitalizeFirst.bind(this);
    }

    componentWillMount() {
        // console.log("memberID: " + JSON.stringify(this.props.memberID))
        if (this.props.hasOwnProperty('memberName')) {
            if (this.props.memberName.length > 0) {
                this.setState({
                    name: this.props.memberName
                })
            } else {
                if (localStorage.getItem("member_name") !== null) {
                    this.setState({
                        name: localStorage.getItem("member_name")
                    })
                }
            }
        }

        let char = Number(localStorage.getItem("fam-member"));

        if (char === null) {
            char = this.props.memberID;
        }

        this.setState({
            memberID: char
        })

    }

    // put in utils folder
    capitalizeFirst = (x) => {
        return x.charAt(0).toUpperCase() + x.slice(1);
    }

    setName = (e) => {
        let name = this.capitalizeFirst(e.target.value);
        this.setState({ name: name })
        this.props.setMemberName(name);
        localStorage.setItem("member_name", name);
    }

    goNext = () => {
        if (this.state.name.trim() !== '') {
            this.props.history.push({
                pathname: '/my-emote'
            })
        } else {
            this.setState({
                errMsg: "Please enter the family member's name..."
            });
        }
    }

    render() {
        return (
            <div id="family-name">
                <div className="top f-width">
                    <a className="backBtn light-blue" onClick={() => this.props.history.goBack()}>
                        <img className="centerX" src={backBtn} alt="" />
                       Back
                    </a>
                    <h3 className="f-width centerAlign dark-blue">When I see...</h3>
                </div>
                <div className="f-width">
                    <input type="text" onChange={this.setName} value={this.state.name} placeholder="Enter name here..." className="q-width centerX" id="fam-name" />
                    {this.state.errMsg !== '' && <p style={{ textAlign: 'center', fontSize: 15, marginLeft: 20, marginBottom: 0, fontWeight: '600', color: '#B164A5' }}>{this.state.errMsg}</p>}

                    {this.state.memberID <= 0 ? <div id="img-cont" className="centerX" style={{
                        backgroundImage: `url(${DefaultChar})`
                    }}></div> : null}
                    {this.state.memberID === 1 ? <div id="img-cont" className="centerX" style={{
                        backgroundImage: `url(${OldMan})`
                    }}></div> : null}
                    {this.state.memberID === 2 ? <div id="img-cont" className="centerX" style={{
                        backgroundImage: `url(${OldWoman})`
                    }}></div> : null}
                    {this.state.memberID === 3 ? <div id="img-cont" className="centerX" style={{
                        backgroundImage: `url(${YoungMan})`
                    }}></div> : null}
                    {this.state.memberID === 4 ? <div id="img-cont" className="centerX" style={{
                        backgroundImage: `url(${YoungWoman})`
                    }}></div> : null}
                    {this.state.memberID === 5 ? <div id="img-cont" className="centerX" style={{
                        backgroundImage: `url(${TeenBoy})`
                    }}></div> : null}
                    {this.state.memberID === 6 ? <div id="img-cont" className="centerX" style={{
                        backgroundImage: `url(${YoungGirl})`
                    }}></div> : null}

                    <div className="f-width centerX rel">
                        <a onClick={this.goNext} className="btn font-weight-bold bg-soft-red white-text centerAlign">
                            Confirm
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(FamilyName);
