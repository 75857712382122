import React, { Component } from 'react';

export default class CharThree extends Component {

    render() {
        
        let trousersEl = <g id="Trousers">
        <rect className="cls-3" x="68.4" y="417.8" width="75" height="60" />
        <rect className="cls-3" x="154.4" y="417.8" width="75" height="60" />
        <rect className="cls-15" x="68.4" y="417.8" width="75" height="129" />
        <rect className="cls-15" x="154.4" y="417.8" width="75" height="129" />
    </g>;
    let shortsEl = <g id="Shorts">
        <rect className="cls-15" x="68.4" y="417.8" width="75" height="60" />
        <rect className="cls-15" x="154.4" y="417.8" width="75" height="60" />
    </g>;

        return (
            <svg id="char_three" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 0 298.9 561.3">
            <defs>
                <clipPath id="clip-path" transform="translate(-188.6 -141.8)">
                    <ellipse className="cls-1" cx="311" cy="308" rx="16" ry="23" />
                </clipPath>
                <clipPath id="clip-path-2" transform="translate(-188.6 -141.8)">
                    <ellipse className="cls-1" cx="347" cy="308" rx="16" ry="23" />
                </clipPath>
            </defs>
            {this.props.showShorts ? shortsEl : trousersEl}

            <g id="Layer_12" data-name="Layer 12">
                <path className="cls-4" d="M459.5,695a20.3,20.3,0,0,1-.8,5.5H358.9a33.1,33.1,0,0,1-.4-5.5,21.8,21.8,0,0,1,1.6-7.9,3.6,3.6,0,0,1,.3-.7h98.2A40.5,40.5,0,0,1,459.5,695Z" transform="translate(-188.6 -141.8)" />
                <path className="cls-5" d="M458.6,686.4H360.4c7.8-18.2,39.2-31.9,58.6-31.9A40.6,40.6,0,0,1,458.6,686.4Z" transform="translate(-188.6 -141.8)" />
                <path className="cls-4" d="M313.5,695a33.1,33.1,0,0,1-.4,5.5H213.3a20.3,20.3,0,0,1-.8-5.5,39.5,39.5,0,0,1,.9-8.5h98.3A20.9,20.9,0,0,1,313.5,695Z" transform="translate(-188.6 -141.8)" />
                <path className="cls-5" d="M311.7,686.5H213.4a40.5,40.5,0,0,1,39.6-32C272.4,654.5,303.8,668.2,311.7,686.5Z" transform="translate(-188.6 -141.8)" />
                <path className="cls-6" d="M213.4,686.5a39.5,39.5,0,0,0-.9,8.5,20.3,20.3,0,0,0,.8,5.5h99.8a33.1,33.1,0,0,0,.4-5.5,20.9,20.9,0,0,0-1.8-8.5c-7.9-18.3-39.3-32-58.7-32A40.5,40.5,0,0,0,213.4,686.5Z" transform="translate(-188.6 -141.8)" />
                <path className="cls-6" d="M458.6,686.4a40.5,40.5,0,0,1,.9,8.6,20.3,20.3,0,0,1-.8,5.5H358.9a33.1,33.1,0,0,1-.4-5.5,21.8,21.8,0,0,1,1.6-7.9,3.6,3.6,0,0,1,.3-.7c7.8-18.2,39.2-31.9,58.6-31.9A40.6,40.6,0,0,1,458.6,686.4Z" transform="translate(-188.6 -141.8)" />
                <line className="cls-6" x1="269.4" y1="544.8" x2="171.9" y2="544.8" />
                <polyline className="cls-6" points="123.4 544.8 123.1 544.8 24.9 544.8" />
            </g>
            <g id="T-shirt">
                <path className="cls-7" fill={ this.props.tshirtColor } d="M435,443.8V462H410.2l5.9,24.1,8.7,34.9,7.5,34.3a15.7,15.7,0,0,1-15.6,19.1H261.8A15.7,15.7,0,0,1,246,556.2l2.8-19.2,9.6-51.2v-.2L263,462H239V443.8A30.8,30.8,0,0,1,269.8,413H404.2A30.8,30.8,0,0,1,435,443.8Z" transform="translate(-188.6 -141.8)" />
            </g>
            <g id="T-shirt_Outline" data-name="T-shirt Outline">
                <path className="cls-8" d="M463.2,537.6A30.3,30.3,0,0,1,484.9,568a30.6,30.6,0,0,1-29.1,29.2c-10.3.4-17.6-5.6-23.4-13.1-.6.8-3.2,1.4-3.8,1.4a13.6,13.6,0,0,1-13.4-11.1h1.5a15.7,15.7,0,0,0,15.6-19.1L424.8,521l-8.7-34.9L410.2,462h18.7Z" transform="translate(-188.6 -141.8)" />
                <path className="cls-8" d="M263,462l-4.6,23.6v.2L248.8,537,246,556.2a15.8,15.8,0,0,0,14.8,18.2,13.6,13.6,0,0,1-13.4,11.1c-.6,0-3.2-.6-3.8-1.4-5.8,7.5-13.1,13.5-23.4,13.1A30.6,30.6,0,0,1,191.1,568a30.3,30.3,0,0,1,21.7-30.4L247.1,462Z" transform="translate(-188.6 -141.8)" />
                <path className="cls-6" d="M255.2,528.7" transform="translate(-188.6 -141.8)" />
                <path className="cls-6" d="M247.1,462l-34.3,75.6A30.3,30.3,0,0,0,191.1,568a30.6,30.6,0,0,0,29.1,29.2c10.3.4,17.6-5.6,23.4-13.1.6.8,3.2,1.4,3.8,1.4a13.6,13.6,0,0,0,13.4-11.1,19.5,19.5,0,0,0,.2-2.5" transform="translate(-188.6 -141.8)" />
                <path className="cls-6" d="M428.9,462l34.3,75.6A30.3,30.3,0,0,1,484.9,568a30.6,30.6,0,0,1-29.1,29.2c-10.3.4-17.6-5.6-23.4-13.1-.6.8-3.2,1.4-3.8,1.4a13.6,13.6,0,0,1-13.4-11.1,19.5,19.5,0,0,1-.2-2.5" transform="translate(-188.6 -141.8)" />
                <path className="cls-6" d="M428.9,462H435V443.8A30.8,30.8,0,0,0,404.2,413H269.8A30.8,30.8,0,0,0,239,443.8V462h24l-4.6,23.6v.2L248.8,537,246,556.2a15.8,15.8,0,0,0,14.8,18.2H416.7a15.7,15.7,0,0,0,15.6-19.1L424.8,521l-8.7-34.9L410.2,462Z" transform="translate(-188.6 -141.8)" />
            </g>
            <g id="Layer_16" data-name="Layer 16">
                <path className="cls-9" fill={ this.props.hairColor } d="M446,377H411.6a126.4,126.4,0,0,0,8.7-31h2.2a23.6,23.6,0,0,0,23.2-19.8C446,342.5,446,359.6,446,377Z" transform="translate(-188.6 -141.8)" />
                <path className="cls-9"  fill={ this.props.hairColor }  d="M265.9,204.8c-2,4.3-18.1,17.1-15.4,50.6-1.4,4.3-2.7,8.6-3.8,13l-26.6,5.8c9.1-62.5,30.3-109.2,75-124.5a105.4,105.4,0,0,1,120.3,81.2l-12.3,2.7c-15-33.9-38.8-60.6-71.1-60.6s-56.7,26.4-72.5,60.1Z" transform="translate(-188.6 -141.8)" />
                <path className="cls-9"  fill={ this.props.hairColor } d="M298.5,256.8l-5.3,1.2H277.9C287.6,253.8,294.9,255.4,298.5,256.8Z" transform="translate(-188.6 -141.8)" />
                <path className="cls-9" fill={ this.props.hairColor } d="M295.1,149.7a105.2,105.2,0,0,1,34.9-5.5c97.6,0,113.1,73.7,115.5,173.6a23.4,23.4,0,0,0-23-18.8h-1.9c-2.6-21.5-8.4-44.8-17.5-65.4l12.3-2.7A105.4,105.4,0,0,0,295.1,149.7Z" transform="translate(-188.6 -141.8)" />
                <polygon className="cls-9" fill={ this.props.hairColor } points="104.7 116.3 89.4 119.7 89.2 116.3 89.4 116.3 104.7 116.3" />
                <path className="cls-9" fill={ this.props.hairColor } d="M295.1,149.7c-44.7,15.3-65.9,62-75,124.5l-10.7,2.4a105.5,105.5,0,0,1,80.2-125.8A48.6,48.6,0,0,1,295.1,149.7Z" transform="translate(-188.6 -141.8)" />
                <path className="cls-9" fill={ this.props.hairColor } d="M276.5,237.5l1.2,20.5.2,3.4C274.4,246.3,276.5,237.5,276.5,237.5Z" transform="translate(-188.6 -141.8)" />
                <path className="cls-9" fill={ this.props.hairColor } d="M265.9,204.8l-6.4,28.3a216.4,216.4,0,0,0-9,22.3C247.8,221.9,263.9,209.1,265.9,204.8Z" transform="translate(-188.6 -141.8)" />
                <path className="cls-9" fill={ this.props.hairColor } d="M251.9,266c-.6-3.7-1.1-7.2-1.4-10.6a216.4,216.4,0,0,1,9-22.3Z" transform="translate(-188.6 -141.8)" />
                <path className="cls-9" fill={ this.props.hairColor } d="M277.9,261.4h0l-31.2,7c1.1-4.4,2.4-8.7,3.8-13,.3,3.4.8,6.9,1.4,10.6l7.6-32.9C275.3,199.4,299.9,173,332,173s56.1,26.7,71.1,60.6L298.5,256.8c-3.6-1.4-10.9-3-20.6,1.2h-.2l-1.2-20.5S274.4,246.3,277.9,261.4Z" transform="translate(-188.6 -141.8)" />
                <path className="cls-9" fill={ this.props.hairColor } d="M249.9,377H214c0-17.6.4-34.8,1.2-51.2A23.5,23.5,0,0,0,238.5,346h2.3A122.6,122.6,0,0,0,249.9,377Z" transform="translate(-188.6 -141.8)" />
                <path className="cls-9" fill={ this.props.hairColor } d="M220.1,274.2l26.6-5.8a212.4,212.4,0,0,0-6.1,30.6h-2.1a23.5,23.5,0,0,0-22.7,17.6C216.7,301.8,218.1,287.7,220.1,274.2Z" transform="translate(-188.6 -141.8)" />
            </g>
            <g id="neck">
                <path className="cls-2" d="M125.9,270.8h44a0,0,0,0,1,0,0v12a22,22,0,0,1-22,22h0a22,22,0,0,1-22-22v-12a0,0,0,0,1,0,0Z" />
            </g>
            <g id="HairFront">
                <path className="cls-6" d="M220.1,274.2c-4.5,31-6.1,65.9-6.1,102.8h36.2" transform="translate(-188.6 -141.8)" />
                <path className="cls-8" d="M445.5,317.8a25,25,0,0,1,.5,4.7,22.2,22.2,0,0,1-.3,3.7C445.7,323.4,445.6,320.6,445.5,317.8Z" transform="translate(-188.6 -141.8)" />
                <path className="cls-8" d="M420.3,346a163.5,163.5,0,0,0,1.7-23.5,217.1,217.1,0,0,0-1.4-23.5h1.9a23.4,23.4,0,0,1,23,18.8c.1,2.8.2,5.6.2,8.4A23.6,23.6,0,0,1,422.5,346Z" transform="translate(-188.6 -141.8)" />
                <path className="cls-8" d="M420.6,299a217.1,217.1,0,0,1,1.4,23.5,163.5,163.5,0,0,1-1.7,23.5H240.8a150.2,150.2,0,0,1-1.8-23.5,177.4,177.4,0,0,1,1.6-23.5Z" transform="translate(-188.6 -141.8)" />
                <path className="cls-8" d="M420.6,299h-180a212.4,212.4,0,0,1,6.1-30.6l31.2-7h0l15.3-3.4H301a13.4,13.4,0,0,0-2.5-1.2l104.6-23.2C412.2,254.2,418,277.5,420.6,299Zm-68.7-39H375S365.9,254,351.9,260Z" transform="translate(-188.6 -141.8)" />
                <path className="cls-8" d="M240.8,346H420.3a126.4,126.4,0,0,1-8.7,31H249.9A122.6,122.6,0,0,1,240.8,346Z" transform="translate(-188.6 -141.8)" />
                <path className="cls-8" d="M249.9,377H411.6c-13.6,31.7-40.6,53-80.1,53S264.2,408.7,249.9,377Z" transform="translate(-188.6 -141.8)" />
                <path className="cls-8" d="M375,260H351.9C365.9,254,375,260,375,260Z" transform="translate(-188.6 -141.8)" />
                <path className="cls-8" d="M301,258h-7.8l5.3-1.2A13.4,13.4,0,0,1,301,258Z" transform="translate(-188.6 -141.8)" />
                <path className="cls-8" d="M240.8,346h-2.3a23.5,23.5,0,0,1-23.3-20.2l.6-9.2A23.5,23.5,0,0,1,238.5,299h2.1a177.4,177.4,0,0,0-1.6,23.5A150.2,150.2,0,0,0,240.8,346Z" transform="translate(-188.6 -141.8)" />
                <path className="cls-8" d="M215.8,316.6l-.6,9.2c-.1-1.1-.2-2.2-.2-3.3A22.7,22.7,0,0,1,215.8,316.6Z" transform="translate(-188.6 -141.8)" />
                <path className="cls-6" d="M420.6,299h1.9a23.4,23.4,0,0,1,23,18.8,25,25,0,0,1,.5,4.7,22.2,22.2,0,0,1-.3,3.7A23.6,23.6,0,0,1,422.5,346h-2.2" transform="translate(-188.6 -141.8)" />
                <path className="cls-6" d="M240.8,346h-2.3a23.5,23.5,0,0,1-23.3-20.2c-.1-1.1-.2-2.2-.2-3.3a22.7,22.7,0,0,1,.8-5.9A23.5,23.5,0,0,1,238.5,299h2.1" transform="translate(-188.6 -141.8)" />
                <path className="cls-6" d="M403.1,233.6c9.1,20.6,14.9,43.9,17.5,65.4a217.1,217.1,0,0,1,1.4,23.5,163.5,163.5,0,0,1-1.7,23.5,126.4,126.4,0,0,1-8.7,31c-13.6,31.7-40.6,53-80.1,53s-67.3-21.3-81.6-53a122.6,122.6,0,0,1-9.1-31,150.2,150.2,0,0,1-1.8-23.5,177.4,177.4,0,0,1,1.6-23.5,212.4,212.4,0,0,1,6.1-30.6" transform="translate(-188.6 -141.8)" />
                <path className="cls-10" d="M375,260s-9.1-6-23.1,0" transform="translate(-188.6 -141.8)" />
                <path className="cls-10" d="M301,258a13.4,13.4,0,0,0-2.5-1.2" transform="translate(-188.6 -141.8)" />
                <path className="cls-6" d="M411.6,377H446c0-17.4,0-34.5-.3-50.8,0-2.8-.1-5.6-.2-8.4-2.4-99.9-17.9-173.6-115.5-173.6a105.2,105.2,0,0,0-34.9,5.5" transform="translate(-188.6 -141.8)" />
                <path className="cls-6" d="M277.9,261.4l15.3-3.4,5.3-1.2,104.6-23.2,12.3-2.7a105.4,105.4,0,0,0-120.3-81.2,48.6,48.6,0,0,0-5.5,1.1,105.5,105.5,0,0,0-80.2,125.8l10.7-2.4,26.6-5.8,31.2-7" transform="translate(-188.6 -141.8)" />
                <path className="cls-6" d="M265.9,204.8c-2,4.3-18.1,17.1-15.4,50.6.3,3.4.8,6.9,1.4,10.6" transform="translate(-188.6 -141.8)" />
                <path className="cls-6" d="M276.5,237.5s-2.1,8.8,1.4,23.9" transform="translate(-188.6 -141.8)" />
                <line className="cls-6" x1="89.4" y1="119.7" x2="89.4" y2="119.7" />
            </g>
            <g id="Head">
                <circle className="cls-11" cx="83.4" cy="203.2" r="19" />
                <circle className="cls-11" cx="201.4" cy="200.2" r="19" />
                <path className="cls-12" d="M373,360c0,9.4-18.8,17-42,17s-42-7.6-42-17" transform="translate(-188.6 -141.8)" />
                <g className="cls-13">
                    <ellipse className="cls-5" cx="122.4" cy="166.2" rx="16" ry="23" />
                    <ellipse className="cls-5" cx="122.4" cy="166.2" rx="16" ry="23" />
                    <circle cx="128.4" cy="167.2" r="9" />
                </g>
                <g className="cls-14">
                    <ellipse className="cls-5" cx="158.4" cy="166.3" rx="16" ry="23" />
                    <ellipse className="cls-5" cx="158.4" cy="166.3" rx="16" ry="23" />
                    <circle cx="152.4" cy="167.2" r="9" />
                </g>
            </g>
        </svg>
);
    }
}