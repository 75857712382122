import React, { Component } from 'react';

export default class CharTwo extends Component {

    render() {
        let trousersEl = <g id="Trousers">
        <rect className="cls-2" x="68.4" y="511" width="75" height="50.7" />
        <rect className="cls-2" x="154.4" y="511" width="75" height="50.7" />
        <rect className="cls-3" x="68.4" y="511" width="75" height="109" />
        <rect className="cls-3" x="154.4" y="511" width="75" height="109" />
    </g>

        let shortsEl = <g id="Shorts">
            <rect className="cls-2" x="173.9" y="545.5" width="35" height="82" />
            <rect className="cls-2" x="83.9" y="541.5" width="35" height="73" />
            <rect className="cls-3" x="68.4" y="511" width="75" height="40" />
            <rect className="cls-3" x="154.4" y="511" width="75" height="40" />
        </g>;
        return (
            <svg xmlns="http://www.w3.org/2000/svg" id="char_two" xlink="http://www.w3.org/1999/xlink" viewBox="0 0 298.9 640">
                <defs>
                    <clipPath id="clip-path" transform="translate(-188.6 -63)">
                        <ellipse className="cls-1" cx="311" cy="308" rx="16" ry="23" />
                    </clipPath>
                    <clipPath id="clip-path-2" transform="translate(-188.6 -63)">
                        <ellipse className="cls-1" cx="347" cy="308" rx="16" ry="23" />
                    </clipPath>
                </defs>

                {this.props.showShorts ? shortsEl : trousersEl}

                <g id="Shoes">
                    <path className="cls-4" d="M459.5,695a20.3,20.3,0,0,1-.8,5.5H358.9a33.1,33.1,0,0,1-.4-5.5,21.8,21.8,0,0,1,1.6-7.9,3.6,3.6,0,0,1,.3-.7h98.2A40.5,40.5,0,0,1,459.5,695Z" transform="translate(-188.6 -63)" />
                    <path className="cls-5" d="M458.6,686.4H360.4c7.8-18.2,39.2-31.9,58.6-31.9A40.6,40.6,0,0,1,458.6,686.4Z" transform="translate(-188.6 -63)" />
                    <path className="cls-4" d="M313.5,695a33.1,33.1,0,0,1-.4,5.5H213.3a20.3,20.3,0,0,1-.8-5.5,39.5,39.5,0,0,1,.9-8.5h98.3A20.9,20.9,0,0,1,313.5,695Z" transform="translate(-188.6 -63)" />
                    <path className="cls-5" d="M311.7,686.5H213.4a40.5,40.5,0,0,1,39.6-32C272.4,654.5,303.8,668.2,311.7,686.5Z" transform="translate(-188.6 -63)" />
                    <path className="cls-6" d="M213.4,686.5a39.5,39.5,0,0,0-.9,8.5,20.3,20.3,0,0,0,.8,5.5h99.8a33.1,33.1,0,0,0,.4-5.5,20.9,20.9,0,0,0-1.8-8.5c-7.9-18.3-39.3-32-58.7-32A40.5,40.5,0,0,0,213.4,686.5Z" transform="translate(-188.6 -63)" />
                    <path className="cls-6" d="M458.6,686.4a40.5,40.5,0,0,1,.9,8.6,20.3,20.3,0,0,1-.8,5.5H358.9a33.1,33.1,0,0,1-.4-5.5,21.8,21.8,0,0,1,1.6-7.9,3.6,3.6,0,0,1,.3-.7c7.8-18.2,39.2-31.9,58.6-31.9A40.6,40.6,0,0,1,458.6,686.4Z" transform="translate(-188.6 -63)" />
                    <line className="cls-6" x1="269.4" y1="623.5" x2="171.9" y2="623.5" />
                    <polyline className="cls-6" points="123.4 623.5 123.1 623.5 24.9 623.5" />
                </g>
                <g id="Layer_20" fill={this.props.hairColor} data-name="Layer 20">
                    <path className="cls-7" fill={this.props.hairColor} d="M371.3,152.2C391.8,158,408,164,408,164l9.3,26.6,9.8,28.1L430,227l-11.1-3.6-16.2,9.4a.3.3,0,0,0-.1-.2C387.6,199.1,364,173,332,173c-17.1,0-32,7.4-44.6,19.4l-58,20.5L232,197s7.9-7.8,18.9-17.6l10.9.3a169.6,169.6,0,0,0,42.4-7.8l61.9-19.7Z" transform="translate(-188.6 -63)" />
                    <path className="cls-7" fill={this.props.hairColor} d="M371.5,250.8l31.1-18.2a.3.3,0,0,1,.1.2Z" transform="translate(-188.6 -63)" />
                    <path className="cls-7" fill={this.props.hairColor} d="M408,164s-16.2-6-36.7-11.8H376V131.7l15.1-14,4.3,18.5,18.6-3.7L409.8,145c25.6,14.4,37.4,50,37.2,74-.4,45-12.1,74.9-25.2,94.3-1.3-25.5-7.7-55.1-19.1-80.5l16.2-9.4L430,227l-2.9-8.3-9.8-28.1Z" transform="translate(-188.6 -63)" />
                    <path className="cls-7" fill={this.props.hairColor} d="M332,173c32,0,55.6,26.1,70.6,59.6l-31.1,18.2V210.4l-51,29.4V204.2c-5.8,1.3-11.9,2.8-17.8,4.3l4.3-23-19.6,6.9C300,180.4,314.9,173,332,173Z" transform="translate(-188.6 -63)" />
                    <path className="cls-7" fill={this.props.hairColor} d="M302.7,208.5l-11.6,62.2-35-30a187.5,187.5,0,0,1,10.1-20.6l4.6-2.2S285.7,213.1,302.7,208.5Z" transform="translate(-188.6 -63)" />
                    <path className="cls-7" fill={this.props.hairColor} d="M229.4,212.9l58-20.5a124.5,124.5,0,0,0-21.2,27.7l-21.8,10.5-15.7-13.4Z" transform="translate(-188.6 -63)" />
                    <path className="cls-7" fill={this.props.hairColor} d="M270.8,217.9l-4.6,2.2a124.5,124.5,0,0,1,21.2-27.7l19.6-6.9-4.3,23C285.7,213.1,270.8,217.9,270.8,217.9Z" transform="translate(-188.6 -63)" />
                    <path className="cls-7" fill={this.props.hairColor} d="M244.4,230.6l21.8-10.5a187.5,187.5,0,0,0-10.1,20.6Z" transform="translate(-188.6 -63)" />
                    <path className="cls-7" fill={this.props.hairColor} d="M256.2,143.8v5.7h-6.6C251.8,147.4,254,145.6,256.2,143.8Z" transform="translate(-188.6 -63)" />
                    <path className="cls-7" fill={this.props.hairColor} d="M228.7,217.2,225,240l19.4-9.4,11.7,10.1a229.7,229.7,0,0,0-15.6,58.8l-2-.5s-50.3-56.2-12.4-120.3l1.7.2,23.1.5C239.9,189.2,232,197,232,197l-2.6,15.9Z" transform="translate(-188.6 -63)" />
                    <polygon className="cls-7" points="55.8 167.6 36.4 177 40.2 154.2 55.8 167.6" />
                </g>
                <g id="T-shirt">
                    <path className="cls-8" fill={this.props.tshirtColor} d="M435,443.8V462H410.2l5.9,24.1,8.7,34.9,7.5,34.3a15.7,15.7,0,0,1-15.6,19.1H261.8A15.7,15.7,0,0,1,246,556.2l2.8-19.2,9.6-51.2v-.2L263,462H239V443.8A30.8,30.8,0,0,1,269.8,413H404.2A30.8,30.8,0,0,1,435,443.8Z" transform="translate(-188.6 -63)" />
                </g>
                <g id="T-shirt_Outline" data-name="T-shirt Outline">
                    <path className="cls-9" d="M463.2,537.6A30.3,30.3,0,0,1,484.9,568a30.6,30.6,0,0,1-29.1,29.2c-10.3.4-17.6-5.6-23.4-13.1-.6.8-3.2,1.4-3.8,1.4a13.6,13.6,0,0,1-13.4-11.1h1.5a15.7,15.7,0,0,0,15.6-19.1L424.8,521l-8.7-34.9L410.2,462h18.7Z" transform="translate(-188.6 -63)" />
                    <path className="cls-9" d="M420.8,528.7" transform="translate(-188.6 -63)" />
                    <path className="cls-9" d="M259,436" transform="translate(-188.6 -63)" />
                    <path className="cls-9" d="M263,462l-4.6,23.6v.2L248.8,537,246,556.2a15.8,15.8,0,0,0,14.8,18.2,13.6,13.6,0,0,1-13.4,11.1c-.6,0-3.2-.6-3.8-1.4-5.8,7.5-13.1,13.5-23.4,13.1A30.6,30.6,0,0,1,191.1,568a30.3,30.3,0,0,1,21.7-30.4L247.1,462Z" transform="translate(-188.6 -63)" />
                    <path className="cls-6" d="M247.1,462l-34.3,75.6A30.3,30.3,0,0,0,191.1,568a30.6,30.6,0,0,0,29.1,29.2c10.3.4,17.6-5.6,23.4-13.1.6.8,3.2,1.4,3.8,1.4a13.6,13.6,0,0,0,13.4-11.1,19.5,19.5,0,0,0,.2-2.5" transform="translate(-188.6 -63)" />
                    <path className="cls-6" d="M428.9,462l34.3,75.6A30.3,30.3,0,0,1,484.9,568a30.6,30.6,0,0,1-29.1,29.2c-10.3.4-17.6-5.6-23.4-13.1-.6.8-3.2,1.4-3.8,1.4a13.6,13.6,0,0,1-13.4-11.1,19.5,19.5,0,0,1-.2-2.5" transform="translate(-188.6 -63)" />
                    <path className="cls-6" d="M428.9,462H435V443.8A30.8,30.8,0,0,0,404.2,413H269.8A30.8,30.8,0,0,0,239,443.8V462h24l-4.6,23.6v.2L248.8,537,246,556.2a15.8,15.8,0,0,0,14.8,18.2H416.7a15.7,15.7,0,0,0,15.6-19.1L424.8,521l-8.7-34.9L410.2,462Z" transform="translate(-188.6 -63)" />
                </g>
                <g id="neck">
                    <path className="cls-2" d="M125.9,349.5h44a0,0,0,0,1,0,0v12a22,22,0,0,1-22,22h0a22,22,0,0,1-22-22v-12a0,0,0,0,1,0,0Z" />
                </g>
                <g id="Head">
                    <rect className="cls-2" x="26.9" y="244.5" width="231" height="47" rx="23.5" />
                    <path className="cls-10" d="M314.5,250.5" transform="translate(-188.6 -63)" />
                </g>
                <g id="Layer_18" data-name="Layer 18">
                    <path className="cls-9" d="M421.8,313.3c.1,3.1.2,6.2.2,9.2,0,59.4-31.1,107.5-90.5,107.5S239,381.9,239,322.5a186,186,0,0,1,1.5-23L272,308l23,15,52-7,38,30S404.6,338.6,421.8,313.3Z" transform="translate(-188.6 -63)" />
                    <path className="cls-9" d="M421.8,313.3C404.6,338.6,385,346,385,346l-38-30-52,7-23-15-31.5-8.5a229.7,229.7,0,0,1,15.6-58.8l35,30,11.6-62.2c5.9-1.5,12-3,17.8-4.3v35.6l51-29.4v40.4l31.2-18C414.1,258.2,420.5,287.8,421.8,313.3Z" transform="translate(-188.6 -63)" />
                </g>
                <g id="FaceStroke">
                    <path className="cls-6" d="M421.8,313.3C434.9,293.9,446.6,264,447,219c.2-24-11.6-59.6-37.2-74l4.2-12.5-18.6,3.7-4.3-18.5-19.3,18C351.2,127,290.4,107.3,246,153c-72,74-7.5,146-7.5,146l2,.5" transform="translate(-188.6 -63)" />
                    <path className="cls-6" d="M402.7,232.8c11.4,25.4,17.8,55,19.1,80.5.1,3.1.2,6.2.2,9.2,0,59.4-31.1,107.5-90.5,107.5S239,381.9,239,322.5a186,186,0,0,1,1.5-23,229.7,229.7,0,0,1,15.6-58.8" transform="translate(-188.6 -63)" />
                </g>
                <g id="Face">
                    <circle className="cls-11" cx="96.4" cy="282" r="19" />
                    <circle className="cls-11" cx="191.4" cy="279" r="19" />
                    <path className="cls-12" d="M359,360c0,9.4-12.5,17-28,17s-28-7.6-28-17" transform="translate(-188.6 -63)" />
                    <g className="cls-13">
                        <ellipse className="cls-5" cx="122.4" cy="245" rx="16" ry="23" />
                        <ellipse className="cls-5" cx="122.4" cy="245" rx="16" ry="23" />
                        <circle cx="128.4" cy="246" r="9" />
                    </g>
                    <g className="cls-14">
                        <ellipse className="cls-5" cx="158.4" cy="245" rx="16" ry="23" />
                        <ellipse className="cls-5" cx="158.4" cy="245" rx="16" ry="23" />
                        <circle cx="152.4" cy="246" r="9" />
                    </g>
                    <path className="cls-15" d="M373.4,279.6s-5.9-9.2-21-9.4" transform="translate(-188.6 -63)" />
                    <path className="cls-15" d="M305.4,274.9s-10.8-1.5-20.8,10" transform="translate(-188.6 -63)" />
                </g>
                <g id="Layer_16" data-name="Layer 16">
                    <path className="cls-1" d="M384.9,467.5a24.4,24.4,0,0,1,.6,5.5c0,11.3-7.4,20.5-16.5,20.5s-16.5-9.2-16.5-20.5a24.4,24.4,0,0,1,.6-5.5Z" transform="translate(-188.6 -63)" />
                    <path className="cls-5" d="M376,131.7v20.5h-4.7l-2.8-.8,1.7-.5a.7.7,0,0,0-.2-1.4h-8.5c-25.5-6.6-54.3-11.6-66.5-4.5-2.2,1.3-4.6,2.8-7.1,4.5H256.2v-5.7c43.1-33.6,96.5-16.1,115.6-8.1Z" transform="translate(-188.6 -63)" />
                    <path className="cls-5" d="M376,119.8v11.9l-4.2,4c-19.1-8-72.5-25.5-115.6,8.1v-24a45.5,45.5,0,0,1,45.5-45.5h7.6c1,3.8,6.9,6.7,14.1,6.7s12.6-2.7,14-6.2A45.5,45.5,0,0,1,376,119.8Z" transform="translate(-188.6 -63)" />
                    <path className="cls-5" d="M371.3,152.2h-5.2l2.4-.8Z" transform="translate(-188.6 -63)" />
                    <path className="cls-16" d="M370,149.5a.7.7,0,0,1,.2,1.4l-1.7.5-7-1.9Z" transform="translate(-188.6 -63)" />
                    <path className="cls-16" d="M368.5,151.4l-2.4.8h-82l3.8-2.7h73.6Z" transform="translate(-188.6 -63)" />
                    <path className="cls-16" d="M366.1,152.2l-61.9,19.7a169.6,169.6,0,0,1-42.4,7.8l-10.9-.3c9.9-8.8,22.1-19.3,33.2-27.2Z" transform="translate(-188.6 -63)" />
                    <path className="cls-16" d="M337.7,73.2a4.1,4.1,0,0,1-.3,1.6,52.1,52.1,0,0,0-6.9-.5H309.3a4.1,4.1,0,0,1-.1-1.1c0-4.2,6.4-7.7,14.2-7.7S337.7,69,337.7,73.2Z" transform="translate(-188.6 -63)" />
                    <path className="cls-5" d="M337.4,74.8c-1.4,3.5-7.1,6.2-14,6.2s-13.1-2.9-14.1-6.7h21.2A52.1,52.1,0,0,1,337.4,74.8Z" transform="translate(-188.6 -63)" />
                    <path className="cls-5" d="M295,145c12.2-7.1,41-2.1,66.5,4.5H287.9C290.4,147.8,292.8,146.3,295,145Z" transform="translate(-188.6 -63)" />
                    <path className="cls-16" d="M256.2,149.5h31.7l-3.8,2.7H256.2Z" transform="translate(-188.6 -63)" />
                    <path className="cls-16" d="M228.2,149.5h21.4L246,153a134.2,134.2,0,0,0-19.9,25.7,14.5,14.5,0,0,1-8.4-4.3,14.4,14.4,0,0,1-4.2-10.2A14.7,14.7,0,0,1,228.2,149.5Z" transform="translate(-188.6 -63)" />
                    <path className="cls-16" d="M227.8,178.9l-1.7-.2A134.2,134.2,0,0,1,246,153l3.6-3.5h6.6v2.7h27.9c-11.1,7.9-23.3,18.4-33.2,27.2Z" transform="translate(-188.6 -63)" />
                    <path className="cls-6" d="M244.4,230.6l11.7,10.1,35,30,11.6-62.2c5.9-1.5,12-3,17.8-4.3v35.6l51-29.4v40.4l31.1-18.2,6.9-4.1" transform="translate(-188.6 -63)" />
                    <path className="cls-6" d="M309.3,74.3a4.1,4.1,0,0,1-.1-1.1c0-4.2,6.4-7.7,14.2-7.7s14.3,3.5,14.3,7.7a4.1,4.1,0,0,1-.3,1.6" transform="translate(-188.6 -63)" />
                    <path className="cls-6" d="M256.2,149.5V119.8a45.5,45.5,0,0,1,45.5-45.5h28.8a52.1,52.1,0,0,1,6.9.5,45.5,45.5,0,0,1,38.6,45v32.4h-9.9" transform="translate(-188.6 -63)" />
                    <path className="cls-6" d="M226.1,178.7l1.7.2,23.1.5,10.9.3a169.6,169.6,0,0,0,42.4-7.8l61.9-19.7,2.4-.8,1.7-.5a.7.7,0,0,0-.2-1.4H228.2a14.7,14.7,0,0,0-14.7,14.7,14.4,14.4,0,0,0,4.2,10.2A14.5,14.5,0,0,0,226.1,178.7Z" transform="translate(-188.6 -63)" />
                    <path className="cls-6" d="M353.1,467.5a24.4,24.4,0,0,0-.6,5.5c0,11.3,7.4,20.5,16.5,20.5s16.5-9.2,16.5-20.5a24.4,24.4,0,0,0-.6-5.5Z" transform="translate(-188.6 -63)" />
                </g>
            </svg>
        );
    }
}