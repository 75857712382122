
import React, { Component } from 'react';

export default class EmotePanel extends Component {

    render() {
        const { text, onSelect, imgDir, selected } = this.props

        return (
            <div onClick={ onSelect } className={ selected ? "emote selected" : "emote" }>
                <img src={ imgDir } className="h-width centerX" alt="" />
        <p className="centerAlign grey">{ text }</p>
            </div>
        );
    }
}