import React, { Component } from 'react';
import { HexColorPicker } from "react-colorful";
import { withRouter } from 'react-router-dom';
import backBtn from '../assets/images/icons/LeftArrow.svg';
import forwardBtn from '../assets/images/icons/RightArrow.svg';
import "react-colorful/dist/index.css";
import CharOne from '../components/characters/CharOne';
import CharTwo from '../components/characters/CharTwo';
import CharThree from '../components/characters/CharThree';
import CharFour from '../components/characters/CharFour';

class CustomizeCharacter extends Component {

    constructor() {
        super();
        this.state = {
            showHairWheel: true,
            showTShirtWheel: false,
            showShorts: false,
            color: "#aabbcc",
            hairColor: '#003300',
            tshirtColor: '#330000',
            character: [{
                id: 0,
                hair: require('../assets/images/customize/character1/Character1_Hair.svg'),
                shorts: require('../assets/images/customize/character1/Character1_Shorts.svg'),
                tshirt: require('../assets/images/customize/character1/Character1_TShirt.svg'),
                trousers: require('../assets/images/customize/character1/Character1_Trousers.svg'),
                toplayer: require('../assets/images/customize/character1/Character1_TopLayer.svg'),
            }]
        };

        this.showHairWheel = this.showHairWheel.bind(this);
        this.showTShirtWheel = this.showTShirtWheel.bind(this);
        this.goNext = this.goNext.bind(this);
    }

    componentWillUpdate() {
        // console.log("UPDATE: " + JSON.stringify(this.state)); 
    }

    componentWillMount() {
        // let hairCol = localStorage.getItem('hairColor');
        // let tshirtColor = localStorage.getItem('TShirtColor');
        // let locals = {};

        // if(hairCol !== null) {
        //     locals.hairColor = hairCol;
        //     this.setState({
        //         hairColor: hairCol
        //     })
        //     this.props.setHairColor(hairCol);
        // }

        // if(tshirtColor !== null){
        //     locals.tshirtColor = tshirtColor;
        //     this.setState({
        //         tshirtColor: tshirtColor
        //     })
        //     this.props.setTShirtColor(tshirtColor)
        // }

        // console.log("Locals: " + JSON.stringify(locals));
        // this.setState(locals);

        if (this.props.hasOwnProperty('characterID')) {
            // console.log("Character: " + this.props.characterID);
            let char = this.state.character;

            if (this.props.characterID <= 0) {
                if (localStorage.getItem('character') !== null) {
                    char[0].id = Number(localStorage.getItem("character"));
                }
            } else {
                char[0].id = this.props.characterID;
            }

            let hairColor;
            let tshirtColor;
            let shorts = localStorage.getItem("shorts") !== null ? Boolean(localStorage.getItem("shorts")) : this.props.shorts;

            if (!this.props.customized
                && localStorage.getItem('TShirtColor') === null
                && localStorage.getItem('hairColor') === null) {
                if (char[0].id === 1) {
                    hairColor = '#6AC1FA';
                    tshirtColor = '#FB785C';
                } else if (char[0].id === 2) {
                    hairColor = '#0D4272';
                    tshirtColor = '#F9DB46';
                } else if (char[0].id === 3) {
                    hairColor = '#FB785C';
                    tshirtColor = '#0D4272';
                } else if (char[0].id === 4) {
                    hairColor = '#F9DB46';
                    tshirtColor = '#6AC1FA';
                }
            } else {
                // hairColor = this.props.hairColor;
                // tshirtColor = this.props.tshirtColor;
                hairColor = localStorage.getItem('hairColor') !== null ? localStorage.getItem('hairColor') : this.state.hairColor;
                tshirtColor = localStorage.getItem('TShirtColor') !== null ? localStorage.getItem('TShirtColor') : this.state.tshirtColor;;
            }

            this.setState({ character: char, hairColor: hairColor, tshirtColor: tshirtColor, showShorts: shorts });
            this.props.setTShirtColor(tshirtColor);
            this.props.setHairColor(hairColor);
            this.props.setShorts(this.props.shorts);
        }
    }

    componentDidMount() {
        this.setState({ showShorts: true })
    }

    setHairColor = (e) => {
        if (localStorage !== void (0)) {
            localStorage.setItem("hairColor", e);
        }
        this.setState({ hairColor: e })
        this.props.setCustomized(true);
        this.props.setHairColor(e);
    }

    setTShirtColor = (e) => {
        if (localStorage !== void (0)) {
            localStorage.setItem("TShirtColor", e);
        }
        this.setState({ tshirtColor: e })
        this.props.setCustomized(true);
        this.props.setTShirtColor(e);
    }

    showHairWheel = (el) => {
        if (localStorage !== void (0)) {
            localStorage.setItem("setWheels", {
                showHairWheel: true,
                showTShirtWheel: false
            });
        }
        this.setState({
            showHairWheel: true,
            showTShirtWheel: false
        })
    }

    showTShirtWheel = (el) => {
        if (localStorage !== void (0)) {
            localStorage.setItem("showTShirtWheel", el);
        }
        this.setState({
            showHairWheel: false,
            showTShirtWheel: true
        })
    }

    toggleShorts = () => {
        localStorage.setItem("shorts", !this.props.shorts);

        this.props.setShorts(!this.props.shorts);
        // console.log("Shorts: " + this.props.shorts);

    }

    goNext = () => {
        this.props.history.push({
            pathname: '/confirm-character'
        })
    }

    render() {
        return (
            <div id="custom-char">
                <div className="top f-width">
                    <a className="backBtn light-blue" onClick={() => this.props.history.goBack()}>
                        <img className="centerX" src={backBtn} alt="" />
                    Back
                    </a>
                    <h3 className="f-width centerAlign dark-blue">Let's have some fun!</h3>
                </div>
                <div className="flex justify-content-center" id="cont">
                    <div className="left rel" >

                        {this.state.character[0].id === 3 ? <CharOne showShorts={this.props.shorts} hairColor={this.state.hairColor} tshirtColor={this.state.tshirtColor} /> : null}
                        {this.state.character[0].id === 1 ? <CharTwo showShorts={this.props.shorts} hairColor={this.state.hairColor} tshirtColor={this.state.tshirtColor} /> : null}
                        {this.state.character[0].id === 2 ? <CharThree showShorts={this.props.shorts} hairColor={this.state.hairColor} tshirtColor={this.state.tshirtColor} /> : null}
                        {this.state.character[0].id === 4 ? <CharFour showShorts={this.props.shorts} hairColor={this.state.hairColor} tshirtColor={this.state.tshirtColor} /> : null}

                        <div className="option">
                            <button onClick={this.showHairWheel} className={this.state.showHairWheel ? "selected f-width leftAlign font-weight-bold" : "f-width leftAlign font-weight-bold"}>Hair Color</button>
                        </div>
                        <div className="option">
                            <button onClick={this.showTShirtWheel} className={this.state.showTShirtWheel ? "selected f-width leftAlign font-weight-bold" : "f-width leftAlign font-weight-bold"}>T-Shirt Color</button>
                        </div>
                        <div className="option rel">
                            <button className="f-width leftAlign font-weight-bold">T-Shirt Color</button>
                            <img src={backBtn} id="leftBtn" onClick={this.toggleShorts} alt="" />
                            <img src={forwardBtn} id="rightBtn" onClick={this.toggleShorts} alt="" />
                        </div>
                    </div>
                    <div className="right flex col">
                        <HexColorPicker className="picker f-width centerX" color={this.state.showHairWheel ? this.state.hairColor : this.state.tshirtColor} onChange={this.state.showHairWheel ? this.setHairColor : this.setTShirtColor} />
                        <p className="light-grey centerAlign f-width">Slide the circle to change colors</p>
                    </div>
                </div>
                <div className="f-width centerX rel" id="btn-cont">
                    <a onClick={this.goNext} className="btn font-weight-bold bg-soft-red white-text centerAlign">
                        Confirm
                        </a>
                </div>
            </div >
        );
    }
}
export default withRouter(CustomizeCharacter);
