import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import CharPanel from '../components/CharPanel';
import backBtn from '../assets/images/icons/LeftArrow.svg';

class FamilyMember extends Component {

    constructor() {
        super();

        this.state = {
            characters: [
                {
                    "id": 1,
                    "imgDir": require('../assets/images/characters/Family/OlderMan.png'),
                    "selected": false,
                    color: '#F9DB46'
                },
                {
                    "id": 2,
                    "imgDir": require('../assets/images/characters/Family/OlderWoman.png'),
                    "selected": false,
                    color: '#6AC1FA'
                },
                {
                    "id": 3,
                    "imgDir": require('../assets/images/characters/Family/YoungMan.png'),
                    "selected": false,
                    color: '#A3ACE1'
                },
                {
                    "id": 4,
                    "imgDir": require('../assets/images/characters/Family/YoungWoman.png'),
                    "selected": false,
                    color: '#68BCC1'
                },
                {
                    "id": 5,
                    "imgDir": require('../assets/images/characters/Family/TeenBoy.png'),
                    "selected": false,
                    color: '#FB795C'
                },
                {
                    "id": 6,
                    "imgDir": require('../assets/images/characters/Family/YoungGirl.png'),
                    "selected": false,
                    color: '#FFB1E4'
                }
            ],
            selected: null,
            errMsg: ''
        }

        this.setSelected = this.setSelected.bind(this);
        this.goNext = this.goNext.bind(this);
    }

    componentWillMount() {
        if (this.props.hasOwnProperty('familyMember')) {
            if (this.props.familyMember > 0) {
                // console.log("Yooo: " + this.props.familyMember);
                this.setState({
                    selected: this.props.familyMember.id
                })
            }
            else {
                if(localStorage.getItem("fam-member") !== null) {
                    this.setState({
                        selected: Number(localStorage.getItem("fam-member"))
                    })
                }
            }
        }

        // if (localStorage.getItem("fam-member") !== null) {
        //     this.setState({
        //         selected: Number(localStorage.getItem("fam-member"))
        //     })
        // } else {
        //     this.setState({
        //         selected: 0
        //     })
        // }
    }

    componentDidMount() {
        // console.log("Props: " + JSON.stringify(this.props));
    }

    setSelected = (el) => {
        this.setState({
            selected: el.id
        });
        localStorage.setItem("fam-member", el.id);
        this.props.setFamilyMember(el);
    }

    goNext = () => {
        if (this.state.selected !== null && typeof this.state.selected !== 'undefined') {
            this.props.history.push({
                pathname: '/family-name'
            })
        } else {
            this.setState({
                errMsg: 'Please select a family member...'
            })
        }
    }

    render() {

        return (
            <div id="choose-character">
                <div className="top f-width">
                    <a className="backBtn light-blue" onClick={() => this.props.history.goBack()}>
                        <img className="centerX" src={backBtn} alt="" />
                       Back
                    </a>
                    <h3 className="f-width centerAlign dark-blue">My family, tell me about...</h3>
                </div>
                <div className="char-panels fam">
                    {this.state.characters.map((l, index) => (
                        <CharPanel
                            imgDir={l.imgDir}
                            color={l.color}
                            selected={l.id === this.state.selected}
                            onSelect={() => {
                                this.setSelected(l)
                            }} />
                    ))}

                    {this.state.errMsg !== '' && <p style={{ fontSize: 15, marginLeft: 20, marginBottom: 0, fontWeight: '600', color: '#B164A5' }}>{this.state.errMsg}</p>}


                    <div className="f-width centerX rel">
                        <a onClick={this.goNext} className="btn font-weight-bold bg-soft-red white-text centerAlign">
                            Confirm
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(FamilyMember);
