import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import backBtn from '../assets/images/icons/LeftArrowWhite.svg';
import CharOne from '../components/characters/CharOne';
import CharTwo from '../components/characters/CharTwo';
import CharThree from '../components/characters/CharThree';
import CharFour from '../components/characters/CharFour';

class ConfirmCharacter extends Component {

    constructor(props) {
        super(props);

        this.state = {
            characterID: this.props.characterID,
            hairColor: this.props.hairColor,
            tShirtColor: this.props.tShirtColor,
            shorts: this.props.shorts,
        }

        this.goNext = this.goNext.bind(this);
    }


    componentWillMount() {

        let charID = Number(localStorage.getItem("character"));
        let hairColor = localStorage.getItem("hairColor");
        let tShirtColor = localStorage.getItem("TShirtColor");

        let shorts = localStorage.getItem("shorts") !== null ? JSON.parse(localStorage.getItem("shorts").toLowerCase()) : this.props.shorts;

        if (localStorage.getItem("character") === null) {
            charID = this.props.characterID;
        }
        if (localStorage.getItem("shorts") === null) {
            shorts = this.props.shorts;
        }

        if (localStorage.getItem("hairColor") === null) {
            hairColor = this.props.hairColor;
        }

        if (localStorage.getItem("TShirtColor") === null) {
            tShirtColor = this.props.tshirtColor;
        }

        if (localStorage.getItem("TShirtColor") === null && localStorage.getItem("hairColor") === null) {
            switch (charID) {
                case 1:
                    hairColor = '#6AC1FA';
                    tShirtColor = '#FB785C';
                break;
                case 2:
                    hairColor = '#0D4272';
                    tShirtColor = '#F9DB46';
                break;
                case 3:
                    hairColor = '#FB785C';
                    tShirtColor = '#0D4272';
                break;
                case 4:
                    hairColor = '#F9DB46';
                    tShirtColor = '#6AC1FA';
                break;
            }
        }


        this.setState({
            characterID: charID,
            hairColor: hairColor,
            tShirtColor: tShirtColor,
            shorts: shorts
        })
    }

    goNext = () => {
        this.props.history.push({
            pathname: '/family-member'
        })
    }

    render() {

        let shorts = localStorage.getItem("shorts") !== null ? JSON.parse(localStorage.getItem("shorts").toLowerCase()) : this.props.shorts;

        return (
            <div className="sky-bg" id="confirm-char">
                <div className="top f-width">
                    <a className="backBtn white-text" onClick={() => this.props.history.goBack()}>
                        <img className="centerX" src={backBtn} alt="" />
                    Back
                    </a>
                    <h3 className="f-width centerAlign dark-blue">Hello. This is me.</h3>
                </div>
                <div className="f-width">
                    <div>
                        {this.state.characterID === 3 ? <CharOne showShorts={ shorts } hairColor={this.state.hairColor} tshirtColor={this.state.tShirtColor} /> : null}
                        {this.state.characterID === 1 ? <CharTwo showShorts={ shorts } hairColor={this.state.hairColor} tshirtColor={this.state.tShirtColor} /> : null}
                        {this.state.characterID === 2 ? <CharThree showShorts={ shorts } hairColor={this.state.hairColor} tshirtColor={this.state.tShirtColor} /> : null}
                        {this.state.characterID === 4 ? <CharFour showShorts={ shorts } hairColor={this.state.hairColor} tshirtColor={this.state.tShirtColor} /> : null}
                    </div>
                    <div className="f-width centerX rel">
                        <a onClick={this.goNext} className="btn font-weight-bold bg-soft-red white-text centerAlign">
                            Confirm
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(ConfirmCharacter);
