
import React, { Component } from 'react';

export default class CharPanel extends Component {

    render() {
        const { width, onSelect, imgDir, color, selected } = this.props

        return (
            <div className={selected ? 'panel' : 'panel selected'}
                 style={{
                    backgroundImage: `url(${imgDir})`,
                    backgroundColor: color,
                    width: width,
                    borderColor: selected ? '#6AC1FA' : ''
                 }}
                 onClick={onSelect} >
            </div>
        );
    }
}