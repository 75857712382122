import React from 'react';
import {
    Route,
    BrowserRouter
} from "react-router-dom";

import Start from '../screens/start';
import ChooseCharacter from '../screens/chooseCharacter';
import CustomizeCharacter from '../screens/customizeCharacter';
import ConfirmCharacter from '../screens/confirmCharacter';
import FamilyMember from '../screens/familyMember';
import FamilyName from '../screens/familyName';
import UserEmote from '../screens/userEmote';
import ThankYou from '../screens/thankYou';
import Confirm from '../screens/complete';
import FamilyEmote from '../screens/familyEmote';

class Main extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            name: '',
            age: '',
            email: '',
            characterID: 0,
            customized: false,
            hairColor: '#003300',
            tshirtColor: '#330000',
            userEmote: '',
            shorts: false,
            familyEmote: '',
            memberName: '',
            familyMember: ''
        }

        this.setName = this.setName.bind(this);
        this.setAge = this.setAge.bind(this);
        this.setEmail = this.setEmail.bind(this);
        this.setCharacter = this.setCharacter.bind(this);
        this.setHairColor = this.setHairColor.bind(this);
        this.setTShirtColor = this.setTShirtColor.bind(this);
        this.setShorts = this.setShorts.bind(this);
        this.setUserEmote = this.setUserEmote.bind(this);
        this.setFamilyMember = this.setFamilyMember.bind(this);
        this.setFamilyEmote = this.setFamilyEmote.bind(this);
        this.setMemberName = this.setMemberName.bind(this);
        this.setCustomized = this.setCustomized.bind(this);
    }

    setName = (name) => {
        this.setState({
            name: name
        })
    }

    setAge = (age) => {
        this.setState({
            age: age
        })
    }

    setEmail = (email) => {
        this.setState({
            email: email
        })
    }

    setCharacter = (character) => {
        this.setState({
            characterID: character
        })
    }

    setHairColor = (color) => {
        this.setState({
            hairColor: color
        })
    }

    setTShirtColor = (color) => {
        this.setState({
            tshirtColor: color
        })
    }

    setShorts = (shorts) => {
        this.setState({
            shorts: shorts
        })
    }

    setUserEmote = (emote) => {
        this.setState({
            userEmote: emote
        })
    }

    setFamilyEmote = (emote) => {
        this.setState({
            familyEmote: emote
        })
    }

    setMemberName = (name) => {
        this.setState({
            memberName: name
        })
    }

    setFamilyMember = (member) => {
        this.setState({
            familyMember: member
        })
    }

    setCustomized = (customized) => {
        this.setState({
            customized: customized
        })
    }

    render() {
        return (
            <BrowserRouter>
                <Route path="/" exact>
                    <Start setName={this.setName} setEmail={this.setEmail} setAge={this.setAge} name={this.state.name} age={this.state.age} email={this.state.email} />
                </Route>
                <Route path="/choose-character" exact>
                    <ChooseCharacter characterID={this.state.characterID} setCharacter={this.setCharacter} />
                </Route>
                <Route path="/customize-character" exact>
                    <CustomizeCharacter characterID={this.state.characterID} customized={this.state.customized} shorts={this.state.shorts} hairColor={this.state.hairColor} tshirtColor={this.state.tshirtColor} setCustomized={this.setCustomized} setTShirtColor={this.setTShirtColor} setHairColor={this.setHairColor} setShorts={this.setShorts} />
                </Route>
                <Route path="/confirm-character" exact>
                    <ConfirmCharacter characterID={this.state.characterID} shorts={this.state.shorts} hairColor={this.state.hairColor} tshirtColor={this.state.tshirtColor} />
                </Route>
                <Route path="/family-member" exact>
                    <FamilyMember familyMember={this.state.familyMember} setFamilyMember={this.setFamilyMember} />
                </Route>
                <Route path="/family-name" exact>
                    <FamilyName memberID={this.state.familyMember.id} memberName={this.state.memberName} setMemberName={this.setMemberName} />
                </Route>
                <Route path="/my-emote" exact>
                    <UserEmote userEmote={this.state.userEmote} setUserEmote={this.setUserEmote} />
                </Route>
                <Route path="/thank-you" exact component={ThankYou} />
                <Route path="/family-emote" exact component={FamilyEmote}>
                    <FamilyEmote familyEmote={ this.state.familyEmote } setFamilyEmote={ this.setFamilyEmote }/>
                </Route>
                <Route path="/complete" exact>
                    <Confirm shorts={this.state.shorts} hairColor={this.state.hairColor} tshirtColor={this.state.tshirtColor} characterID={this.state.characterID} name={this.state.name} age={this.state.age} email={this.state.email} memberName={this.state.memberName} user_emote={this.state.userEmote} fam_emote={this.state.familyEmote} />
                </Route>
            </BrowserRouter>
        );
    }
}


export default Main;
