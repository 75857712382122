
import React, { Component } from 'react';

export default class CharOne extends Component {

    render() {

        let trousersEl = <g id="Trousers">
            <rect className="cls-3" x="75" y="434.5" width="75" height="60" />
            <rect className="cls-3" x="161" y="434.5" width="75" height="60" />
            <rect className="cls-2" x="75" y="454.5" width="75" height="109" />
            <rect className="cls-2" x="161" y="454.5" width="75" height="109" />
        </g>;

        let shortsEl = <g id="Shorts">
            <rect className="cls-2" x="75" y="454.5" width="75" height="40" />
            <rect className="cls-2" x="161" y="454.5" width="75" height="40" />
        </g>;

        return (
            <svg id="char_one" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 305.45 583.5">
                <defs>
                    <clipPath id="clip-path" transform="translate(-182 -119.5)">
                        <ellipse className="cls-1" cx="311" cy="308" rx="16" ry="23" />
                    </clipPath>
                    <clipPath id="clip-path-2" transform="translate(-182 -119.5)">
                        <ellipse className="cls-1" cx="347" cy="308" rx="16" ry="23" />
                    </clipPath>
                </defs>

                {this.props.showShorts ? shortsEl : trousersEl}
                <g id="Layer_12" data-name="Layer 12">
                    <path className="cls-4" d="M459.5,695a20.48,20.48,0,0,1-.79,5.5H358.88a41.52,41.52,0,0,1-.38-5.5,21.22,21.22,0,0,1,1.56-7.86c.09-.23.19-.47.29-.7l.15.06H458l.58-.13A40.89,40.89,0,0,1,459.5,695Z" transform="translate(-182 -119.5)" />
                    <path className="cls-5" d="M458.58,686.37l-.58.13H360.5l-.15-.06c7.89-18.25,39.22-31.94,58.65-31.94A40.52,40.52,0,0,1,458.58,686.37Z" transform="translate(-182 -119.5)" />
                    <path className="cls-4" d="M313.5,695a41.52,41.52,0,0,1-.38,5.5H213.29a20.48,20.48,0,0,1-.79-5.5,40.58,40.58,0,0,1,.9-8.52l.1,0h98.17A21.41,21.41,0,0,1,313.5,695Z" transform="translate(-182 -119.5)" />
                    <path className="cls-5" d="M311.67,686.5H213.5l-.1,0a40.5,40.5,0,0,1,39.6-32C272.45,654.5,303.83,668.21,311.67,686.5Z" transform="translate(-182 -119.5)" />
                    <path className="cls-6" d="M213.4,686.48a40.58,40.58,0,0,0-.9,8.52,20.48,20.48,0,0,0,.79,5.5h99.83a41.52,41.52,0,0,0,.38-5.5,21.41,21.41,0,0,0-1.83-8.5c-7.84-18.29-39.22-32-58.67-32A40.5,40.5,0,0,0,213.4,686.48Z" transform="translate(-182 -119.5)" />
                    <path className="cls-6" d="M458.58,686.37a40.89,40.89,0,0,1,.92,8.63,20.48,20.48,0,0,1-.79,5.5H358.88a41.52,41.52,0,0,1-.38-5.5,21.22,21.22,0,0,1,1.56-7.86c.09-.23.19-.47.29-.7,7.89-18.25,39.22-31.94,58.65-31.94A40.52,40.52,0,0,1,458.58,686.37Z" transform="translate(-182 -119.5)" />
                    <line className="cls-6" x1="276" y1="567" x2="178.5" y2="567" />
                    <polyline className="cls-6" points="130 567 129.67 567 31.5 567" />
                </g>
                <g id="T-shirt">
                    <path className="cls-7" fill={this.props.tshirtColor} d="M435,443.82V462H410.15l6,24.14L424.77,521l7.51,34.29a15.76,15.76,0,0,1-15.59,19.16l-2.44,0H262.5l-.66,0A15.78,15.78,0,0,1,246,556.15L248.78,537l9.63-51.21,0-.16L263,462H239V443.82A30.82,30.82,0,0,1,269.82,413H404.18A30.82,30.82,0,0,1,435,443.82Z" transform="translate(-182 -119.5)" />
                </g>
                <g id="T-shirt_Outline" data-name="T-shirt Outline">
                    <path className="cls-8" d="M463.24,537.57a30.45,30.45,0,0,1-7.44,59.59c-10.32.43-17.6-5.58-23.42-13.07-.56.79-3.16,1.44-3.79,1.44a13.59,13.59,0,0,1-13.37-11.12l1.47,0a15.76,15.76,0,0,0,15.59-19.16L424.77,521l-8.65-34.84-6-24.14h18.72Z" transform="translate(-182 -119.5)" />
                    <path className="cls-8" d="M263,462l-4.56,23.61,0,.16L248.78,537,246,556.15a15.78,15.78,0,0,0,14.75,18.25,13.6,13.6,0,0,1-13.38,11.13c-.63,0-3.23-.65-3.79-1.44-5.82,7.49-13.1,13.5-23.42,13.07a30.45,30.45,0,0,1-7.44-59.59L247.13,462Z" transform="translate(-182 -119.5)" />
                    <path className="cls-6" d="M255.21,528.74" transform="translate(-182 -119.5)" />
                    <path className="cls-6" d="M247.13,462l-34.37,75.57a30.45,30.45,0,0,0,7.44,59.59c10.32.43,17.6-5.58,23.42-13.07.56.79,3.16,1.44,3.79,1.44a13.6,13.6,0,0,0,13.38-11.13,13.46,13.46,0,0,0,.23-2.48" transform="translate(-182 -119.5)" />
                    <path className="cls-6" d="M428.87,462l34.37,75.57a30.45,30.45,0,0,1-7.44,59.59c-10.32.43-17.6-5.58-23.42-13.07-.56.79-3.16,1.44-3.79,1.44a13.59,13.59,0,0,1-13.37-11.12,12.74,12.74,0,0,1-.24-2.49" transform="translate(-182 -119.5)" />
                    <path className="cls-6" fill={this.props.tshirtColor} d="M428.87,462H435V443.82A30.82,30.82,0,0,0,404.18,413H269.82A30.82,30.82,0,0,0,239,443.82V462h24l-4.56,23.61,0,.16L248.78,537,246,556.15a15.78,15.78,0,0,0,14.75,18.25c.34,0,.7,0,1,0l.66,0H415.22l1.47,0a15.76,15.76,0,0,0,15.59-19.16L424.77,521l-8.65-34.84-6-24.14Z" transform="translate(-182 -119.5)" />
                    <line className="cls-6" x1="154" y1="326.5" x2="154" y2="356.5" />
                    <circle className="cls-9" cx="164" cy="341.5" r="3.5" />
                </g>
                <g id="Layer_16" fill={this.props.hairColor} data-name="Layer 16">
                    <path className="cls-10" d="M411.4,255.86a48,48,0,0,1-42.9-11.55,68.94,68.94,0,0,1-11.33,8.22,70.1,70.1,0,0,1-56.25,6.23,56.62,56.62,0,0,1-8.53,5.62A55.61,55.61,0,0,1,266,271a56.19,56.19,0,0,1-19.15-3.35C260,219.46,289.37,173,332,173,371.13,173,397.78,212.16,411.4,255.86Z" transform="translate(-182 -119.5)" />
                    <path className="cls-10" d="M411.4,255.86a48.08,48.08,0,0,0,33.83-28.17,55,55,0,0,1-1.46,84.83A23.47,23.47,0,0,0,422.5,299h-1.93A241.78,241.78,0,0,0,411.4,255.86Z" transform="translate(-182 -119.5)" />
                    <path className="cls-10" d="M212.5,231.58a56.14,56.14,0,0,0,34.35,36.07A230.45,230.45,0,0,0,240.56,299H238.5A23.49,23.49,0,0,0,215,322.5a23,23,0,0,0,1,6.73,55,55,0,0,1-3.49-97.65Z" transform="translate(-182 -119.5)" />
                    <path className="cls-10" d="M449,209a47.93,47.93,0,0,1-37.6,46.86C397.78,212.16,371.13,173,332,173c-42.63,0-72,46.46-85.15,94.65A56,56,0,0,1,260.1,159.31a70,70,0,0,1,125.81,4.11A48,48,0,0,1,449,209Z" transform="translate(-182 -119.5)" />
                </g>
                <g id="neck">
                    <path className="cls-3" d="M132.5,293h44a0,0,0,0,1,0,0v12a22,22,0,0,1-22,22h0a22,22,0,0,1-22-22V293A0,0,0,0,1,132.5,293Z" />
                </g>
                <g id="HairColour1">
                    <path className="cls-8" d="M238.5,299h2.06A188.85,188.85,0,0,0,239,322.5q0,6.09.47,12A54.6,54.6,0,0,1,216,329.23a23,23,0,0,1-1-6.73A23.49,23.49,0,0,1,238.5,299Z" transform="translate(-182 -119.5)" />
                    <path className="cls-8" d="M266,271a55.61,55.61,0,0,0,26.39-6.62A55.2,55.2,0,0,1,290.94,299H240.56a230.45,230.45,0,0,1,6.29-31.35A56.19,56.19,0,0,0,266,271Z" transform="translate(-182 -119.5)" />
                    <path className="cls-8" d="M443.77,312.52A54.77,54.77,0,0,1,422,323.07v-.57a204.45,204.45,0,0,0-1.43-23.5h1.93A23.47,23.47,0,0,1,443.77,312.52Z" transform="translate(-182 -119.5)" />
                    <path className="cls-8" d="M240.83,346H238.5A23.48,23.48,0,0,1,216,329.23a54.6,54.6,0,0,0,23.48,5.27Q239.92,340.35,240.83,346Z" transform="translate(-182 -119.5)" />
                    <path className="cls-8" d="M422,323.07A157,157,0,0,1,420.28,346H240.83q-.92-5.64-1.36-11.5h0A55,55,0,0,0,290.94,299h72.14A55.06,55.06,0,0,0,422,323.07Z" transform="translate(-182 -119.5)" />
                    <path className="cls-8" d="M422,322.5v.57A55.06,55.06,0,0,1,363.08,299h57.49A204.45,204.45,0,0,1,422,322.5Z" transform="translate(-182 -119.5)" />
                    <path className="cls-8" d="M368.5,244.31a48,48,0,0,0,42.9,11.55A241.78,241.78,0,0,1,420.57,299H363.08a55.1,55.1,0,0,1-5.91-46.47A68.94,68.94,0,0,0,368.5,244.31Z" transform="translate(-182 -119.5)" />
                    <path className="cls-8" d="M240.56,299h50.38a55,55,0,0,1-51.44,35.5h0q-.48-5.91-.47-12A188.85,188.85,0,0,1,240.56,299Z" transform="translate(-182 -119.5)" />
                    <path className="cls-8" d="M363.08,299H290.94a55.2,55.2,0,0,0,1.45-34.62,56.62,56.62,0,0,0,8.53-5.62,70.1,70.1,0,0,0,56.25-6.23A55.1,55.1,0,0,0,363.08,299Z" transform="translate(-182 -119.5)" />
                    <path className="cls-8" d="M240.83,346H420.28c-7.27,48.07-37.48,84-88.78,84S248.49,394.07,240.83,346Z" transform="translate(-182 -119.5)" />
                    <path className="cls-8" d="M420.28,346A157,157,0,0,0,422,323.07a54.77,54.77,0,0,0,21.77-10.55A23.49,23.49,0,0,1,422.5,346Z" transform="translate(-182 -119.5)" />
                    <path className="cls-6" d="M420.57,299h1.93a23.5,23.5,0,1,1,0,47h-2.22" transform="translate(-182 -119.5)" />
                    <path className="cls-6" d="M240.83,346H238.5A23.48,23.48,0,0,1,216,329.23a23,23,0,0,1-1-6.73A23.49,23.49,0,0,1,238.5,299h2.06" transform="translate(-182 -119.5)" />
                    <path className="cls-6" d="M411.4,255.86A241.78,241.78,0,0,1,420.57,299,204.45,204.45,0,0,1,422,322.5v.57A157,157,0,0,1,420.28,346c-7.27,48.07-37.48,84-88.78,84s-83-35.93-90.67-84q-.92-5.64-1.36-11.5t-.47-12a188.85,188.85,0,0,1,1.56-23.5,230.45,230.45,0,0,1,6.29-31.35" transform="translate(-182 -119.5)" />
                </g>
                <g id="Head">
                    <circle className="cls-11" cx="103" cy="225.5" r="19" />
                    <circle className="cls-11" cx="198" cy="222.5" r="19" />
                    <path className="cls-12" d="M359,360c0,9.39-12.54,17-28,17s-28-7.61-28-17" transform="translate(-182 -119.5)" />
                    <g className="cls-13">
                        <ellipse className="cls-5" cx="129" cy="188.5" rx="16" ry="23" />
                        <ellipse className="cls-5" cx="129" cy="188.5" rx="16" ry="23" />
                        <circle cx="135" cy="189.5" r="9" />
                    </g>
                    <g className="cls-14">
                        <ellipse className="cls-5" cx="165" cy="188.5" rx="16" ry="23" />
                        <ellipse className="cls-5" cx="165" cy="188.5" rx="16" ry="23" />
                        <circle cx="159" cy="189.5" r="9" />
                    </g>
                    <path className="cls-15" d="M373.45,279.6s-5.9-9.22-21.06-9.43" transform="translate(-182 -119.5)" />
                    <path className="cls-15" d="M305.44,274.88s-10.84-1.45-20.8,10" transform="translate(-182 -119.5)" />
                    <path className="cls-16" d="M314.5,250.5" transform="translate(-182 -119.5)" />
                    <path className="cls-16" d="M308.93,196.82" transform="translate(-182 -119.5)" />
                </g>
                <g id="HairFront" fill={this.props.hairColor}>
                    <path className="cls-6" d="M216,329.23a55,55,0,0,1-3.48-97.65" transform="translate(-182 -119.5)" />
                    <path className="cls-6" d="M292.39,264.38" transform="translate(-182 -119.5)" />
                    <path className="cls-6" d="M357.17,252.53" transform="translate(-182 -119.5)" />
                    <path className="cls-6" d="M445.23,227.69a55,55,0,0,1-.72,84.23" transform="translate(-182 -119.5)" />
                    <path className="cls-6" d="M385.91,163.42a48,48,0,1,1-17.41,80.89" transform="translate(-182 -119.5)" />
                    <path className="cls-6" d="M385.91,163.42a70,70,0,0,0-125.81-4.11,56,56,0,1,0,32.29,105.07,56.62,56.62,0,0,0,8.53-5.62,70.1,70.1,0,0,0,56.25-6.23,68.94,68.94,0,0,0,11.33-8.22,70,70,0,0,0,17.42-80.88Z" transform="translate(-182 -119.5)" />
                </g>
            </svg>);
    }
}