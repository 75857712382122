import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import backBtn from '../assets/images/icons/LeftArrowWhite.svg';
import StarsBG from '../assets/images/Stars-BG.png';

class ThankYou extends Component {

    constructor(props) {
        super(props);

        this.goNext = this.goNext.bind(this);
    }
 
    goNext = () => {
            this.props.history.push({
                pathname: '/family-emote'
            })
    }

    render() {

        return (
            <div className="sky-bg" id="thank-you">
                <div className="top f-width">
                    <a className="backBtn white-text" onClick={() => this.props.history.goBack()}>
                        <img className="centerX" src={backBtn} alt="" />
                       Back
                    </a>
                    <h3 className="f-width centerAlign dark-blue">Thank You!</h3>
                </div>

                <div className="centerX h-width" id="star-cont">
                    <img className="f-width" src={StarsBG} alt="" />
                    <h3 className="dark-blue centerAlign">You are a star!</h3>
                </div>

                <div className="f-width centerX rel pb-1">
                    <a onClick={this.goNext} className="btn font-weight-bold bg-soft-red white-text centerAlign">
                        Next
                        </a>
                </div>
            </div>
        );
    }
}
export default withRouter(ThankYou);
