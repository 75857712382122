import React from 'react';
import './App.scss';
import Main from './screens/Main'

function App() {
  return (
    <Main />
  );
}

export default App;
