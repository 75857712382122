import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import backBtn from '../assets/images/icons/LeftArrowWhite.svg';
import Star from '../assets/images/Star.png';
import Config from '../config'
import DefaultChar from '../assets/images/characters/Character-01.png';
import CharOne from '../components/characters/CharOne';
import CharTwo from '../components/characters/CharTwo';
import CharThree from '../components/characters/CharThree';
import CharFour from '../components/characters/CharFour';


class Confirm extends Component {

    constructor(props) {
        super(props);

        this.goNext = this.goNext.bind(this);
    }


    goNext = () => {

        let reqObj = {
            name: this.props.name,
            age: this.props.age,
            email: this.props.email,
            family_member: this.props.memberName,
            family_emotion: this.props.fam_emote,
            my_emotion: this.props.user_emote
        }

        fetch(Config.character_gen, {
            credentials: 'include',
            body: JSON.stringify(reqObj),
            headers: Config.getDefaultHeaders(),
            method: 'POST',
        }).then(function (response) {
           
            return response.json();
        }).catch((err) => { console.log(err); });

        localStorage.clear();
        window.location.href = "/";

    }


    render() {

        return (
            <div className="sky-bg" id="complete">
                <div className="top f-width">
                    <a className="backBtn white-text" onClick={() => this.props.history.goBack()}>
                        <img className="centerX" src={backBtn} alt="" />
                       Back
                    </a>
                    <h3 className="f-width centerAlign dark-blue">Super Job!</h3>
                </div>

                <div className="centerX h-width rel" id="star-cont">
                    <h3 className="f-width centerAlign dark-blue">Thank you for your answers!</h3>
                    <img className="f-width centerX" id="star" src={Star} alt="" />
                    {/* <img className="q-width" id="char" src={DefaultChar} alt="" /> */}
                    {this.props.characterID <= 0 ? <img className="q-width" id="char" src={DefaultChar} alt="" /> : null}
                    {this.props.characterID === 3 ? <CharOne showShorts={this.props.shorts} hairColor={this.props.hairColor} tshirtColor={this.props.tshirtColor} /> : null}
                    {this.props.characterID === 1 ? <CharTwo showShorts={this.props.shorts} hairColor={this.props.hairColor} tshirtColor={this.props.tshirtColor} /> : null}
                    {this.props.characterID === 2 ? <CharThree showShorts={this.props.shorts} hairColor={this.props.hairColor} tshirtColor={this.props.tshirtColor} /> : null}
                    {this.props.characterID === 4 ? <CharFour showShorts={this.props.shorts} hairColor={this.props.hairColor} tshirtColor={this.props.tshirtColor} /> : null}
                </div>

                <div className="f-width centerX rel" id="btn-cont">
                    <a onClick={this.goNext} className="btn bg-light-blue font-weight-bold white-text centerAlign">
                        Send and reset app
                        </a>
                </div>
            </div>
        );
    }
}
export default withRouter(Confirm);
